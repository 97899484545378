<template>
    <div style="line-height: 1;">
        <span style="font-size: 12px; opacity: 0.5;"><b>{{value?.code ? value.code + ' - ' : ''}}</b></span>
        <span style="font-size: 12px;">{{ value?.name ?? '' }}</span>
    </div>
</template>

<script>
export default {
    data: () => ({
        value: null,
    }),
    beforeMount() {
        this.value = this.params.value
    },
}


</script>