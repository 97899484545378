<template>
    <div style="height: calc(100vh - 25px); contain: content; background-color: var(--v-greyBase-base) !important;">
        <div id="Tabs" class="tabsTop d-flex" v-if="$store.state.user.id == 69">
            <v-tabs v-model="selectedTabs" height="30px" :background-color="'var(--v-greyBase-base) !important'">
                <v-tab v-for="(tab, index) in tabs" :key="index" :href="'#tab-' + tab.name">
                    {{ tab.name }}
                </v-tab>
            </v-tabs>
        </div>
        <v-tabs-items v-model="selectedTabs" style="height: 100%">
            <v-tab-item value="tab-Containers">
        <!-- TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR -->
                <div id="parentContainer" style="display: flex; width: 100%; contain: content;">
                    <div style="width: 100%; display: grid">
                        <div class="secondRow">
                            <v-chip :color="selectedTemplate?.title ? 'var(--v-primary-darken2)' : ''" :outlined="selectedTemplate?.title ? false : true" small dense class="mr-2 px-2" @click="sidePanelSwitch('templates')"><v-icon class="chipIcon">view_carousel</v-icon>{{selectedTemplate ? selectedTemplate.title ?? 'Layout' : 'Layout'}}</v-chip>
                            <v-chip small dense class="mr-2 px-2" color="var(--v-redPop-darken1)" v-if="filtersActive" @click="clearFilters"><v-icon small class="pr-2" >filter_list_off</v-icon>Clear Filters</v-chip>
                            <v-spacer></v-spacer>
                            <span class="mr-1 text"><b>{{ currentWeek ?? '' }}</b></span>
                            <div style="width: 20vw;">
                                <v-chip style="display: grid" class="px-2">
                                    <v-tooltip top open-delay="500">
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" x-small icon color="primary" @click="addOrder()">
                                                <v-icon>add_circle_outline</v-icon>
                                            </v-btn>
                                        </template>
                                        Create Order
                                    </v-tooltip>
                                    <v-tooltip top open-delay="500">
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" x-small icon color="teal" @click="openAvailableBookings()">
                                                <v-icon>add_circle_outline</v-icon>
                                            </v-btn>
                                        </template>
                                        Create Order From Booking
                                    </v-tooltip>
                                    <v-icon small color="grey" class="ml-1">search</v-icon>
                                    <v-text-field placeholder="Search" class="mb-1 searchInput" hide-details rounded clearable dense v-model="search" ></v-text-field>
                                    <v-tooltip top v-if="filtersActive">
                                        <template v-slot:activator="{ on }">
                                          <v-btn rounded x-small icon color="var(--v-redPop-darken1)" @click="clearFilters" v-on="on" >
                                            <v-icon>filter_list_off</v-icon>
                                          </v-btn>
                                        </template>
                                        <span style="font-size: 0.75rem">Clear Filters</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon x-small color="blue" @click="getContainers" v-on="on" >
                                                <v-icon>autorenew</v-icon>
                                            </v-btn>
                                        </template>
                                        <span style="font-size: 0.75rem">Refresh</span>
                                    </v-tooltip>
                                </v-chip>
                            </div>
                            <v-menu bottom offset-y nudge-right :close-on-content-click="false" v-if="showCharts">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip v-on="on" v-bind="attrs" small class="px-1 ml-1" color="var(--v-greyRaised-lighten3)" >
                                        <v-icon small>settings</v-icon>
                                    </v-chip>
                                </template>
                                <v-card class="px-2 pb-2" style="background: var(--v-greyDarker-base) !important">
                                    <v-subheader style="height: 30px;" class="px-0">
                                        Graphs
                                        <v-spacer></v-spacer>
                                        <v-chip class="px-1" small @click="graphsLock = !graphsLock" :color="graphsLock ? 'grey' : 'greyBase'"><v-icon small>{{graphsLock ? 'lock' : 'lock_open'}}</v-icon></v-chip>
                                    </v-subheader>
                                    <div class="d-flex" style="gap: 5px;">
                                        <v-card :disabled="graphsLock" @click="graphs[0].enabled = !graphs[0].enabled" :color="graphs[0].enabled ? 'var(--v-greyBase-base) !important' : 'var(--v-greyRaised-base) !important'" class="graphState">
                                            <b>1</b>
                                            <v-icon size="80" color="grey">stacked_bar_chart</v-icon>
                                        </v-card>
                                        <v-card :disabled="graphsLock" @click="graphs[1].enabled = !graphs[1].enabled" :color="graphs[1].enabled ? 'var(--v-greyBase-base) !important' : 'var(--v-greyRaised-base) !important'" class="graphState">
                                            <b>2</b>
                                            <v-icon size="80" color="grey">pie_chart</v-icon>
                                        </v-card>
                                        <v-card :disabled="graphsLock" @click="graphs[2].enabled = !graphs[2].enabled" :color="graphs[2].enabled ? 'var(--v-greyBase-base) !important' : 'var(--v-greyRaised-base) !important'" class="graphState">
                                            <b>3</b>
                                            <v-icon size="80" color="grey">area_chart</v-icon>
                                        </v-card>
                                        <v-card :disabled="graphsLock" @click="graphs[3].enabled = !graphs[3].enabled" :color="graphs[3].enabled ? 'var(--v-greyBase-base) !important' : 'var(--v-greyRaised-base) !important'" class="graphState">
                                            <b>4</b>
                                            <v-icon size="80" color="grey">bubble_chart</v-icon>
                                        </v-card>
                                    </div>
                                </v-card>
                            </v-menu>
                            <v-chip small class="px-1 ml-1" :color="showCharts ? 'tertiary' : 'var(--v-greyRaised-lighten3)'" @click="showChart" v-if="$store.state.user.id == 69">
                                <v-icon small>query_stats</v-icon>
                            </v-chip>
                        </div>
                        <div v-if="showCharts" style="height: 35vh; min-height: 50px" class="topSection">
                            <div class="graphGrid g1" id="graphGrid-1" v-if="graphs[0].enabled" :style="{'width': `calc((100vw - (${graphs.filter(x => x.enabled).length * 1}px)) / ${graphs.filter(x => x.enabled).length} )`}"></div>
                            <div class="gutter1" @mousedown="handleResizeGraphs('.gutter1')" v-if="!graphsLock && graphs.some((x, i) => i > 0 && x.enabled ) && graphs[0].enabled"><div class="vertHandle"></div></div>
                            <div class="graphGrid g2" id="graphGrid-2" v-if="graphs[1].enabled" :style="{'width': `calc((100vw - (${graphs.filter(x => x.enabled).length * 1}px)) / ${graphs.filter(x => x.enabled).length} )`}"></div>
                            <div class="gutter2" @mousedown="handleResizeGraphs('.gutter2')" v-if="!graphsLock && graphs.some((x, i) => i > 1 && x.enabled ) && graphs[1].enabled"><div class="vertHandle"></div></div>
                            <div class="graphGrid g3" id="graphGrid-3" v-if="graphs[2].enabled" :style="{'width': `calc((100vw - (${graphs.filter(x => x.enabled).length * 1}px)) / ${graphs.filter(x => x.enabled).length} )`}"></div>
                            <div class="gutter3" @mousedown="handleResizeGraphs('.gutter3')" v-if="!graphsLock && graphs.some((x, i) => i > 2 && x.enabled ) && graphs[2].enabled"><div class="vertHandle"></div></div>
                            <div class="graphGrid g4" id="graphGrid-4" v-if="graphs[3].enabled" :style="{'width': `calc((100vw - (${graphs.filter(x => x.enabled).length * 1}px)) / ${graphs.filter(x => x.enabled).length} )`}"></div>
                        </div>
                        <div v-if="showCharts" class="gutter" @mousedown="handleResizeMain()"><div class="handle"></div></div>
                        <div :style="showCharts ? {'height': 'calc(100vh - 35vh - 66px - 25px)'} : {'height': 'calc(100vh - 66px - 25px)'}" style="width: auto; overflow-x: scroll; min-height: 30vh" class="bottomSection">
                            <ag-grid-vue style="width: auto; height: 100%;"
                                class="ag-grid-theme-builder"
                                :columnDefs="headers"
                                :chartThemes="chartThemes"
                                :chartThemeOverrides="chartThemeOverrides"
                                :autoSizeStrategy="autoSizeStrategy"
                                :suppressAggFuncInHeader="true"
                                
                                :loading="loading"
                                :hideOverlay="loading"
                                :rowData="containers"
                                :autoGroupColumnDef="autoGroupColumnDef"
                                :defaultColDef="defaultColumn"
                                :enableCharts="enableCharts"
                                :popupParent="popUpParent"
                                :enableRangeSelection="enableRangeSelection"
                                :rowGroupPanelShow="rowGroupPanelShow"
                                :loadingOverlayComponent="loadingOverlayComponent"
                                :statusBar="statusBar"
                                :sideBar="sideBarDef"
                                :getContextMenuItems="getContextMenuItems"
                                :chartToolPanelsDef="chartToolPanelsDef"
                                @grid-ready="onGridReady"
                                @columnMoved="columnsChanged"
                                @columnPivotChanged="columnsChanged"
                                @newColumnsLoaded="columnsChanged"
                                @columnValueChanged="columnsChanged"
                                @columnPinned="columnsChanged"
                                @displayedColumnsChanged="columnsChanged"
                                @columnResized="columnsChanged"
                                @columnRowGroupChanged="columnsChanged"
                                @columnPivotModeChanged="columnsChanged"
                                :createChartContainer="$store.state.user.id == 69 ? createChartContainer : null"
                            >
                            </ag-grid-vue>
                        </div>
                    </div>
                </div>
                <v-dialog v-model="orderModal" persistent width="75vw" style="height: auto;" class="rightDialog">
                  <CreateOrder
                    :order="createOrderData"
                    :organisations="organisations"
                    :loadingRelated="loadingOrganisations"
                    @close="(orderModal = false), (createOrderData = {})"
                    @refresh="getContainers()"
                  />
                </v-dialog>
                <v-dialog v-model="bookingModal" width="800px">
                  <v-card :loading="loadingBookings">
                    <v-toolbar dense flat>
                      <v-toolbar-title>Available Bookings</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn plain icon small @click="bookingModal = false"><v-icon color="redPop">close</v-icon></v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-text-field v-model="searchBookings" placeholder="Search" outlined dense clearable prepend-inner-icon="search" ></v-text-field>
                      <div class="loadingContainer" v-if="!loadingBookings && bookingsToConvert.data.length == 0" >
                        <h3 style="color: grey">No Available Bookings</h3>
                      </div>
                      <div class="loadingContainer" v-else-if="loadingBookings">
                        <h3 style="color: grey">Loading...</h3>
                      </div>
                      <v-virtual-scroll v-else-if="bookingsToConvert.data.length > 0" :items="availableBookings" item-height="200" height="500" >
                        <template v-slot:default="{ item }">
                          <v-card style=" border-radius: 20px; background: var(--v-component-base) !important; " @click="confirmBookingToOrder(item)" >
                          <v-toolbar flat dense>
                            <v-toolbar-title style="font-size: 14px">
                              {{ item.orderNo }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-chip small outlined color="primary" v-if="item.vessel">
                              <v-icon small left>directions_boat</v-icon>
                              {{ item.vessel.name }}
                              <span right>{{ item.mainCarriageConveyanceNumber }}</span>
                            </v-chip>
                          </v-toolbar>
                            <v-card-text>
                              <v-row justify="center">
                                <v-col cols="12" sm="4">
                                  <v-list dense subheader>
                                    <v-subheader>Parties</v-subheader>
                                    <v-divider></v-divider>
                                    <!-- Shipper -->
                                    <v-list-item style="height: 35px" v-if="item.shipper">
                                      <v-list-item-action>
                                        <v-avatar :color="item.shipper.logo ? 'white' : 'secondary'" size="32" >
                                          <v-img v-if="item.shipper.logo" :src="item.shipper.logo" contain ></v-img>
                                          <h3 v-else>{{ item.shipper.name.charAt(0) }}</h3>
                                        </v-avatar>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">{{
                                          item.shipper.name
                                        }}</v-list-item-title>
                                         <v-list-item-subtitle style="font-size: 12px">
                                          Shipper
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <!--     Forwarder -->
                                    <v-list-item style="height: 35px" v-if="item.forwarder">
                                      <v-list-item-action>
                                        <v-avatar :color="item.forwarder.logo ? 'white' : 'secondary'" size="32" >
                                            <v-img v-if="item.forwarder.logo" :src="item.forwarder.logo" contain ></v-img>
                                          <h3 v-else>{{ item.forwarder.name.charAt(0) }}</h3>
                                        </v-avatar>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">{{ item.forwarder.name }}</v-list-item-title>
                                         <v-list-item-subtitle style="font-size: 12px">
                                          Forwarder
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <!-- Buyer -->
                                    <v-list-item style="height: 35px" v-if="item.buyer">
                                      <v-list-item-action>
                                        <v-avatar :color="item.buyer.logo ? 'white' : 'secondary'" size="32" >
                                          <v-img v-if="item.buyer.logo" :src="item.buyer.logo" contain ></v-img>
                                          <h3 v-else>{{ item.buyer.name.charAt(0) }}</h3>
                                        </v-avatar>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">{{ item.buyer.name }}</v-list-item-title>
                                         <v-list-item-subtitle style="font-size: 12px">
                                          Buyer
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                      <!-- Consignee -->
                                      <v-list-item style="height: 35px" v-if="item.consignee">
                                      <v-list-item-action>
                                        <v-avatar :color="item.consignee.logo ? 'white' : 'secondary'" size="32" >
                                          <v-img v-if="item.consignee.logo" :src="item.consignee.logo" contain ></v-img>
                                          <h3 v-else>{{ item.consignee.name.charAt(0) }}</h3>
                                        </v-avatar>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">{{ item.consignee.name }}</v-list-item-title>
                                        <v-list-item-subtitle style="font-size: 12px">
                                          Consignee
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-col>
                                <v-col cols="4">
                                  <v-list dense subheader>
                                    <v-subheader>Details</v-subheader>
                                    <v-divider></v-divider>
                                    <v-list-item style="height: 35px">
                                      <v-list-item-action>
                                        <v-icon small color="grey">anchor</v-icon>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">
                                          <span v-if="item.portOfLoadValue">
                                            {{ item.portOfLoadValue }}
                                          </span>
                                      <span v-else>-</span>
                                    </v-list-item-title>
                                        <v-list-item-subtitle style="font-size: 12px">
                                          Port Of Load
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-action>
                                        <v-avatar size="28" v-if="item.portOfLoadValue"> <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue .substring(0, 2) .toLowerCase()}.svg`" />
                                            </v-avatar>
                                      </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item style="height: 35px">
                                      <v-list-item-action>
                                        <v-icon small color="grey">anchor</v-icon>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">
                                          <span v-if="item.portOfDischargeValue">
                                            {{ item.portOfDischargeValue }}
                                          </span>
                                      <span v-else>-</span>
                                    </v-list-item-title>
                                        <v-list-item-subtitle style="font-size: 12px">
                                          Port Of Discharge
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-action>
                                        <v-avatar size="28" v-if="item.portOfDischargeValue">
                                              <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.portOfDischargeValue .substring(0, 2) .toLowerCase()}.svg`" />
                                            </v-avatar>
                                      </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item style="height: 35px">
                                      <v-list-item-action>
                                        <v-icon small color="grey">location_on</v-icon>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">
                                          <span v-if="item.finalDestinationValue">
                                            {{ item.finalDestinationValue }}
                                          </span>
                                      <span v-else>-</span>
                                    </v-list-item-title>
                                        <v-list-item-subtitle style="font-size: 12px">
                                          Final Destination
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-action>
                                        <v-avatar size="28" v-if="item.finalDestinationValue">
                                              <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue .substring(0, 2) .toLowerCase()}.svg`" />
                                            </v-avatar>
                                      </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item style="height:35px">
                                    <v-list-item-action>
                                      <v-icon small color="grey">event</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title style="font-size: 12px">
                                        {{ $Format.formatDate(item.etd).dayMonthYear }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle style="font-size: 12px">
                                        ETD
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item style="height:35px">
                                    <v-list-item-action>
                                      <v-icon small color="grey">event</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title style="font-size: 12px">
                                        {{ $Format.formatDate(item.eta).dayMonthYear }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle style="font-size: 12px">
                                        ETA
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-col>
                                <v-col cols="4">
                              <v-list dense subheader>
                                <v-subheader>Cargo</v-subheader>
                                <v-divider></v-divider>
                                <v-list-item style="height: 35px">
                                  <v-list-item-content>
                                    <v-list-item-title style="font-size: 12px">
                                      {{ item.numContainers }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 12px">
                                      Containers
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-subheader>References</v-subheader>
                                <v-divider></v-divider>
                                <v-list-item style="height: 35px">
                                  <v-list-item-content>
                                    <v-list-item-title style="font-size: 12px">
                                      {{ item.shipperReferenceNo }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 12px">
                                      Shipper Ref. No
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item style="height: 35px">
                                  <v-list-item-content>
                                    <v-list-item-title style="font-size: 12px">
                                      {{ item.consigneeReferenceNo }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 12px">
                                      Consignee Ref. No
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item style="height: 35px">
                                  <v-list-item-content>
                                    <v-list-item-title style="font-size: 12px">
                                      {{ item.carrierReferenceNumber }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 12px">
                                      Carrier Ref. No
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>
                              </v-list>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </template>
                      </v-virtual-scroll>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="confirmBookingConversionModal" width="500px">
                    <v-card :loading="convertingBooking">
                      <v-toolbar dense flat color="transparent">
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <v-card-text>
                        <p style="font-size: 14px">
                          Are you sure you would like to convert <b>{{selectedBookingToConvert.orderNo}}</b> into an Order?
                        </p>
                        <v-row justify="center" class="mt-5 mb-3">
                          <v-btn color="red" class="mx-2" text small @click="confirmBookingConversionModal = false" >No</v-btn >
                          <v-btn color="success" class="mx-2" text small @click="convertBooking(selectedBookingToConvert)" >Yes</v-btn >
                        </v-row>
                      </v-card-text>
                    </v-card>
                </v-dialog>
            </v-tab-item>
            <v-tab-item value="tab-Forecasts" style="height: 100%;">
                <Forecasts />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import * as moment from 'moment'
import {ref} from 'vue'
import CreateOrder from '../components/Orders/CreateOrder.vue'
import Forecasts from '../components/TradingView/OrderForecasts.vue'
/* eslint-disable vue/no-unused-components */
import Templates from '../components/AgGrid/GridSideBar/Templates.vue'
import CellRenders from '../components/AgGrid/Cells/CellRenders.vue'
import LoadingOverlay from '../components/AgGrid/LoadingOverlay.vue'
/* eslint-disable vue/no-unused-components */
export default {
    components: {
        CreateOrder,
        AgGridVue,
        CellRenders,
        Forecasts,
        LoadingOverlay,
        Templates
    },
    data: () => ({
        showCharts: false,
        rowGroupPanelShow: 'always',
        chartToolPanelsDef: {
            defaultToolPanel: "settings",
            panels: ['data', 'format', 'settings']
        },
        autoSizeStrategy: 'fitCellContents',
        loadingOverlayComponent: 'LoadingOverlay',
        sideBarDef: null,
        defaultColumn: {
            sortable: true,
            filter: 'agMultiColumnFilter',
            enableRowGroup: true,
            cellClass: 'd-flex align-center',
            cellRenderer: 'CellRenders',
            enablePivot: true,
        },
        graphsLock: false,
        graphs: [
            {enabled: true, elementId: '#graphGrid-1'},
            {enabled: false, elementId: '#graphGrid-2'},
            {enabled: false, elementId: '#graphGrid-3'},
            {enabled: false, elementId: '#graphGrid-4'},
        ],
        chartThemeOverrides: {
            common: {
                    background: {
                        fill: '#333333',
                    },
                    axes: {
                        number: {
                        
                        },
                        category: {
                            label: {
                                color: 'white',
                            },
                            
                        }
                    },
                },
        },
        headers: [
            {
                headerName: "Week-Year ETD",
                field: "etdWeekYear",
            },
            {
                headerName: "Booking Status",
                field: "state",
            },
            {
                headerName: "Order Status",
                field: "orderStatus",
            },
            {
                headerName: "OrderNo",
                field: "orderNo",
            },
            {
                headerName: "Allocated",
                field: "stockAllocationStatus",
                cellClass: 'd-flex align-center justify-center',
                cellRendererParams: {type: 'stockAllocationStatus'},
                getQuickFilterText: params => {
                    return ''
                },
            },
            {
                headerName: "LL No",
                field: "ctoNo",
            },
            {
                headerName: "SType",
                field: "serviceType",
            },
            {
                headerName: "Container No",
                field: "containerNo",
                aggFunc: 'count',
                enableValue: true,
            },
            {
                headerName: "Features",
                field: "features",
                getQuickFilterText: params => {
                    return ''
                },
                filter: false,
                cellClass: 'd-flex align-center justify-center',
                cellRenderer: 'CellRenders',
                cellRendererParams: (params) => {
                    return {
                        type: 'features',
                        propData: params.data?.temporaryProducts ?? null
                    }
                }
            },
            // FIXME: FIX BELOW
            {
                headerName: "Products",
                field: "product",
                cellClass: 'd-flex align-center justify-center',
                getQuickFilterText: params => {
                    let result = ''
                    if(params.data?.temporaryProducts) {
                        let array = params.data.temporaryProducts.map((item) => {
                            return `${item.name ?? ''} ${item.code ?? ''}`
                        })
                        result = array.join(' ')
                    }
                    return result
                },
            },
            // FIXME: FIX ABOVE
            {
                headerName: "Shipper",
                field: "shipperName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.shipper ?? null
                    }
                }
            },
            {
                headerName: "ShipperRef",
                field: "shipperReferenceNo",
            },
            {
                headerName: "Buyer",
                field: "buyerName",
                chartDataType: 'category',
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.buyer ?? null
                    }
                }
            },
            {
                headerName: "Consignee",
                field: "consigneeName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.consignee ?? null
                    }
                }
            },
            {
                headerName: "Consignee Ref",
                field: "consigneeReferenceNo",
            },
            {
                headerName: "Forwarder",
                field: "forwarderName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.forwarder ?? null
                    }
                }
            },
            {
                headerName: "Forwarder Ref",
                field: "fileNumber",
            },
            {
                headerName: "Carrier Ref",
                field: "carrierReferenceNumber",
            },
            {
                headerName: "On Behalf Shipper",
                field: "onBehalfShipperName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.onBehalfShipper ?? null
                    }
                }
            },
            {
                headerName: "On Behalf Forwarder",
                field: "onBehalfForwarderName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.onBehalfForwarder ?? null
                    }
                }
            },
            {
                headerName: "On Behalf Buyer",
                field: "onBehalfBuyerName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.onBehalfBuyer ?? null
                    }
                }
            },
            {
                headerName: "On Behalf Consignee",
                field: "onBehalfConsigneeName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.onBehalfConsignee ?? null
                    }
                }
            },
            {
                headerName: "Docs",
                field: "fileStatus",
            },
            {
                headerName: "Carrier",
                field: "shippingLineName",
                cellRendererParams: (params) => {
                    return {
                        type: 'shippingLine',
                        propData: params.data?.shippingLine ?? null
                    }
                }
            },
            {
                headerName: "Vessel",
                field: "vesselVoyage",
            },
            {
                headerName: "Origin Country",
                field: "originCountryName",
            },
            {
                headerName: "POL",
                field: "portOfLoadValue",
                cellRendererParams:{
                    type: 'locode'
                }
            },
            {
                headerName: "POD",
                field: "portOfDischargeValue",
                cellRendererParams:{
                    type: 'locode'
                }
            },
            {
                headerName: "Final Dest",
                field: "finalDestinationValue",
                cellRendererParams:{
                    type: 'locode'
                }
            },
            {
                headerName: "Dest Country",
                field: "destinationCountryName",
            },
            {
                headerName: 'Week ETA',
                field: 'etaWeek'
            },
            {
                headerName: 'Year ETA',
                field: 'etaYear'
            },
            {
                headerName: 'Week ETD',
                field: 'etdWeek'
            },
            {
                headerName: 'Year ETD',
                field: 'etdYear'
            },
            {
                headerName: "Week-Year ETA",
                field: "etaWeekYear",
            },
            // FIXME: FIX BELOW
            {
                headerName: "ETD",
                field: "etd",
                filterParams: {
                    filters: [
                        {
                            filter: 'agDateColumnFilter',
                            display: 'subMenu',
                        },
                        {
                            filter: 'agSetColumnFilter',
                        }
                    ]
                }
            },
            {
                headerName: "ETA",
                field: "eta",
                filterParams: {
                    filters: [
                        {
                            filter: 'agDateColumnFilter',
                            display: 'subMenu',
                        },
                        {
                            filter: 'agSetColumnFilter',
                        }
                    ]
                }
            },
            // FIXME: FIX ABOVE
            {
                headerName: "Pallets",
                field: "totalPallets",
                aggFunc: 'sum',
                chartDataType: 'series',
                enableValue: true,
                filterParams: {
                    filters: [
                        {
                            filter: 'agNumberColumnFilter',
                            display: 'subMenu',
                        },
                        {
                            filter: 'agSetColumnFilter',
                        }
                    ]
                }
            },
            {
                headerName: "Units ",
                field: "totalPackageCount",
                aggFunc: 'sum',
                chartDataType: 'series',
                enableValue: true,
                filterParams: {
                    filters: [
                        {
                            filter: 'agNumberColumnFilter',
                            display: 'subMenu',
                        },
                        {
                            filter: 'agSetColumnFilter',
                        }
                    ]
                }
            },
            {
                headerName: "INCO (FF)",
                field: "incoTerm",
            },
            {
                headerName: "Deal Term",
                field: "financialType",
            },
            {
                headerName: "INCO",
                field: "financialIncoTerm",
            },
            {
                headerName: "Freight Term",
                field: "freightTerm",
            },
            
        ],
        tab: null,  
        rightCard: false,
        dateMenu: false,
        statusBar: {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent' },
                { statusPanel: 'agTotalRowCountComponent' },
                { statusPanel: 'agFilteredRowCountComponent' },
                { statusPanel: 'agSelectedRowCountComponent' },
                { statusPanel: 'agAggregationComponent' }
            ]
        },
        autoGroupColumnDef: {
            headerName: 'Model',
            field: 'model',
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
                checkbox: true
            }
        },
        templateCreate: {
            title: null,
            allowedParties: ['Everyone'],
            tags: [],
            filters: [],
        },
        selectedTabs: 'tab-Containers',
        tabs: [
            {
                icon: 'list',
                name: 'Containers'
            },
            {
                icon: 'event_upcoming',
                name: 'Forecasts'
            },
        ],
        selectedTemplate: null,
        createOrderData : {},
        orderModal: false,
        shortcutKey: 123,
        chartThemes: ['ag-polychroma-dark', 'ag-vivid-dark', 'ag-material-dark', 'ag-sheets-dark', 'ag-default-dark'],
        customChartThemes: {
            myCustomTheme: {
                palette: {
                    fills: ['#42a5f5', '#ffa726', '#81c784'],
                    strokes: ['#000000', '#424242'],
                },
                overrides: {
                    common: {
                        background: {
                            fill: '#f4f4f4',
                        },
                        legend: {
                            item: {
                                label: {
                                    color: '#333333',
                                },
                            },
                        },
                    },
                },    
            },
        },
        searchBookings: '',
        convertingBooking: false,
        enableCharts: true,
        canUpdateOwnTemplate: false,
        canUpdateOrgTemplate: false,
        enableRangeSelection: true,
        tableType: 'Shipments-Containers',
        gridApi: null,
        vesselVoyageSet: [],
        shippingLinesSet: [],
        bookingsToConvert: { data: [] },
        selectedBookingToConvert: {},
        popUpParent: null,
        confirmBookingConversionModal: false,
        loadingBookings: false,
        gridLoaded: false,
        bookingModal: false,
        bookingQuery: undefined,
        bookingTimeout: undefined,
        loading: true,
        userTemplates: [],
        updateKeys: 0,
        shortcutKeyChip: 1154,
        loadingTemplateSave: false,
        baseKey: 1,
        primaryKey: 123,
        loadingTemplates: false,
        secondaryKey: 456,
        searchTimeout: undefined,
        tertiaryKey: 789,
        collapsed: false,
        loadingOrganisations: false,
        organisations: {
          total: 0,
          data: [],
        },
        currentWeek: null,
        search: '',
        status: [
            {
                name: 'Planned',
                color: '#9d5aa8'
            },
            {
                name: 'Awaiting Departure',
                color: '#dc9f44'
            },
            {
                name: 'In Transit',
                color: '#417eaf'
            },
            {
                name: 'Arrived',
                color: '#4c8d4f'
            },
            {
                name: 'Cancelled',
                color: '#cd584f'
            }
        ],
        containers: null,
        
        filtersActive: false,
        filterModel: {},
    }),
    watch: {
        updateKeys: {
          handler(val) {
              this.baseKey++
              this.primaryKey++
              this.secondaryKey++
              this.tertiaryKey++
          }
        },
        search: {
            immediate: true,
            handler(val) {
                if(this.searchTimeout) {
                    clearTimeout(this.searchTimeout)
                }
                this.searchTimeout = setTimeout(() => {
                    this.searchTimeout = undefined
                    this.gridApi.setGridOption('quickFilterText', val)
                }, 500)
            }
        },
        showCharts: {
            immediate: true,
            handler(val) {
                localStorage.setItem(`showCharts-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, val)
            }
        }
    },
    beforeMount(){
        this.popUpParent = document.querySelector("body")
    },
    mounted() {
        this.loading = true
        if(localStorage.getItem(`showCharts-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`)) {
            this.showCharts = JSON.parse(localStorage.getItem(`showCharts-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`))
            this.initResizeMain()
            this.initResizeGraphs('.gutter1')
            this.initResizeGraphs('.gutter2')
            this.initResizeGraphs('.gutter3')
        }
        this.setSideBar()
        this.setCurrentWeek()
    },
    created () {
        this.loading = true
        this.getContainers()
        this.getRelations()
        this.setCurrentWeek()
    },
    computed: {
        availableBookings(){
          let result = this.bookingsToConvert.data ? JSON.parse(JSON.stringify(this.bookingsToConvert.data)) : []
          if(this.searchBookings && result.length > 0) {
            result = result.filter(x =>
            x.orderNo.toLowerCase().includes(this.searchBookings.toLowerCase()) ||
            (x.consigneeName && x.consigneeName.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.shipperName && x.shipperName.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.buyerName && x.buyerName.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.vessel && x.vessel.name.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.mainCarriageConveyanceNumber && x.mainCarriageConveyanceNumber.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.carrierReferenceNumber && x.carrierReferenceNumber.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.shipperReferenceNo && x.shipperReferenceNo.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.consigneeReferenceNo && x.consigneeReferenceNo.toLowerCase().includes(this.searchBookings.toLowerCase()))
          )
          }
          return result
        },
    },
    methods: {
        setSideBar() {
            this.sideBarDef = {
              toolPanels: [
                {
                  id: "templates",
                  labelDefault: "Templates",
                  labelKey: "templates",
                  iconKey: 'colorPicker',
                  toolPanel: "Templates",
                  minWidth: 300,
                  maxWidth: 500,
                  width: 300,
                  toolPanelParams: {
                    tableType: this.tableType,
                    fSetTemplate: true,
                    setTemplate: (selectedTemplate) => {
                        if(selectedTemplate.id) {
                            this.selectedTemplate = selectedTemplate
                        }
                    }
                  },
                },
                {
                  id: "columns",
                  labelDefault: "Columns",
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel",
                  minWidth: 300,
                  maxWidth: 500,
                  width: 300,
                },
                {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel",
                  minWidth: 300,
                  maxWidth: 500,
                  width: 300,
                },
              ],
              defaultToolPanel: "ActionPanel",
            };
        },
        watchFilters(event) {
          let filter = this.gridApi.getFilterModel();
          this.filtersActive = Object.keys(filter).length > 0
          this.filterModel = filter
          localStorage.setItem(`filterModel-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(this.gridApi.getFilterModel()))
        },
        getContextMenuItems(params) {
            let result = [];
            if (!params.node.group) {
                result = [
                  {
                    name: "View Order",
                    icon:'<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: white; opacity: 0.65; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
                    action: () => {
                      this.viewShipment(params, 'order');
                    },
                  },
                  {
                    name: "View Container",
                    icon:'<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: white; opacity: 0.65; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
                    action: () => {
                      this.viewShipment(params, 'container');
                    },
                  },
                  'separator',
                  {
                    name: "Create Order",
                    icon:'<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: #729fd9; margin-bottom: 0px; padding-bottom: 0px">add_circle_outline</span>',
                    action: () => {
                      this.addOrder()
                    },
                  },
                  {
                    name: "Create Order From Booking",
                    icon:'<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: teal; margin-bottom: 0px; padding-bottom: 0px">add_circle_outline</span>',
                    action: () => {
                      this.openAvailableBookings();
                    },
                  },
                  'separator',
                  'cut',
                  'copy',
                  'copyWithHeaders',
                  'copyWithGroupHeaders',
                  'paste',
                  'separator',
                  'autoSizeAll',
                  'expandAll',
                  'contractAll',
                  'resetColumns',
                  'separator',
                  {
                    name: "Interactive Charts",
                    icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">query_stats</span>',
                  },
                  'chartRange',
                  'export',
                ];
                if(params.column.colDef.filter) {
                    result.unshift({
                    name: "Add to Filter",
                    icon:'<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: white; opacity: 0.65; margin-bottom: 0px; padding-bottom: 0px">filter_list</span>',
                    action: () => {
                        if (params.node && params.node.data) {
                        let multiFilterInstance = this.gridApi.getFilterInstance(params.column.colId);
                        let childFilterInstance = multiFilterInstance.getChildFilterInstance(1);
                        if (childFilterInstance && childFilterInstance.filterNameKey == 'setFilter') {
                                let filterModel = childFilterInstance.getModel();
                                let values = [];
                                if (filterModel && filterModel.values) {
                                    values = filterModel.values;
                                    values.push(params.node.data[params.column.colId]);
                                } else {
                                    values = [params.node.data[params.column.colId]];
                                }
                                childFilterInstance.setModel({ filterType: 'set', values: values });
                                this.gridApi.onFilterChanged();
                            } else {
                                console.error("Child filter instance not found for column:", params.column.colId);
                            }
                        } else {
                            console.error("Filter instance not found for column:", params.column.colId);
                        }
                    },
                  },
                  'separator'
                )
                }
            } else {
                result = [
                {
                    name: "Create Order",
                    action: () => {
                      this.addOrder()
                    },
                  },
                  {
                    name: "Create Order From Booking",
                    action: () => {
                      this.openAvailableBookings();
                    },
                  },
                  'separator',
                  'cut',
                  'copy',
                  'copyWithHeaders',
                  'copyWithGroupHeaders',
                  'paste',
                  'separator',
                  'autoSizeAll',
                  'expandAll',
                  'contractAll',
                  'resetColumns',
                  'separator',
                  'chartRange',
                  'export',
                ];
            }
                return result;
            },
        viewShipment(data, type) {
            let item;
            console.log(data)
            if(data.node.data) {
                item = data.node.data
                if(item.shipmentReference || item.systemReference) {
                    sessionStorage.setItem('tradingItem', JSON.stringify(item));
                    type ? sessionStorage.setItem('tradingType', type) : sessionStorage.setItem('tradingType', 'shipment'); 
                    if(type === 'container') {
                      sessionStorage.setItem('tradingType', 'order')
                      let obj = {
                        tab: 'overview',
                        shortcut: 'container',
                        id: item.id
                      }
                      sessionStorage.setItem('TradingShortcut', JSON.stringify(obj))
                    } else {
                      sessionStorage.removeItem('TradingShortcut')
                    }
                    this.$router.push({
                    path: "/trading/summary/" + (type === 'shipment' ? item.shipmentReference : item.systemReference),
                  });
                } else {
                    this.$message.error('No Shipment Reference')
                }
            } else {
                console.log('No Data')
            }
        },
        clearFilters() {
          this.gridApi.setFilterModel(null);
          localStorage.setItem(`filterModel-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(this.gridApi.getFilterModel()))
        },
        columnsChanged() {
            if(this.gridApi && this.gridLoaded) {
                let columnState = this.gridApi.getColumnState()
                localStorage.setItem(`columnState-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(columnState))
                localStorage.setItem(`pivotMode-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify({pivotMode: this.gridApi.isPivotMode()}))
            }
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridApi.addEventListener("filterChanged", this.watchFilters);
            this.gridLoaded = true
        },
        createChartContainer(chartRef) {
            let chartEl = chartRef.chartElement
            this.showCharts = true
            let theme = 'ag-polychroma-dark'
            let available = this.graphs.filter(x => !x.graph)
            let parentEl;
            if(available.length == 0) {
                this.graphs[3].enabled = true
                this.graphs[3].graph = chartEl
            } else {
                available[0].graph = chartEl
                available[0].enabled = true
            }
            console.log(chartEl)
            this.$nextTick(() => {
                if(available.length == 0) {
                    parentEl = document.querySelector(this.graphs[3].elementId)
                } else {
                parentEl = document.querySelector(available[0].elementId)
                }
            const chartWrapperHTML = `
            <div class="chart-wrapper ${theme}">
                  <div class="chart-wrapper-body"></div>
                  </div>
                  `;
            parentEl.insertAdjacentHTML("beforeend", chartWrapperHTML);
            const eChartWrapper = parentEl.lastElementChild;
            eChartWrapper.querySelector(".chart-wrapper-body").appendChild(chartEl);
        })
        },
        async getRelations() {
          this.loadingOrganisations = true;
          if ( localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id] ) {
            this.organisations = JSON.parse( localStorage[ "relatedOrganisations_" + this.$store.state.currentOrg.id ] );
          }
          this.organisations = await this.$API.getRelationBasic({ });
          let findCurrentOrg = this.organisations.data.find( (x) => x.relatedOrganisationId == this.$store.state.currentOrg.id );
          if (!findCurrentOrg) {
            this.organisations.data.unshift({
              relatedOrganisationId: this.$store.state.currentOrg.id,
              relatedOrganisation: this.$store.state.currentOrg,
            });
          }
          this.loadingOrganisations = false;
        },
        async convertBooking(booking) {
          try {
            this.convertingBooking = true;
          let result = await this.$API.convertBookingToOrder(booking.id);
          this.convertingBooking = false;
          this.confirmBookingConversionModal = false;
          this.$message.success('Booking '+booking.orderNo+' converted to order successfully!')
          this.getBookings();
          this.getContainers();
          } catch(e){
            this.$message.error('Error converting booking to order')
            this.convertingBooking = false
          }
        },
        async getBookings() {
          if (this.bookingTimeout) {
            clearTimeout(this.bookingTimeout);
          }
          if (this.loadingBookings && this.bookingQuery) {
            this.bookingQuery.abort();
          }
          this.loadingBookings = true;
          let params = {
            queryType: "OrderOverview",
            dates: [
              new Date(new Date().setDate(new Date().getDate() - 60))
                .toISOString()
                .substring(0, 10),
              new Date(new Date().setDate(new Date().getDate() + 30))
                .toISOString()
                .substring(0, 10),
            ],
          };
          this.bookingTimeout = setTimeout(async () => {
            this.bookingQuery = new AbortController();
            const signal = this.bookingQuery.signal;
            this.bookingsToConvert = await this.$API.getBookingOverview({
              params: params,
              signal,
            });
            this.loadingBookings = false;
          }, 500);
        },
        openAvailableBookings() {
          this.getBookings();
          this.bookingModal = true;
        },
        confirmBookingToOrder(booking){
          this.selectedBookingToConvert = booking;
          this.confirmBookingConversionModal = true
        },
        sidePanelSwitch(key) {
            let current = this.gridApi.getOpenedToolPanel()
            if(current && current == key) {
                this.gridApi.closeToolPanel()
            } else {
                this.gridApi.openToolPanel(key)
            }
        },
        addOrder() {
          this.createOrderData = {
            recordType: "ORDER",
            orderStatus: "Planned",
            status: "Draft",
            transportType: "Container",
            movementType: "EXPORT",
            type: "LOGISTICS",
            modeOfTransport: "OCEAN",
            serviceType: "FCL",
            bookingContainerItems: [],
            plannedYear: new Date().getFullYear(),
            plannedWeek: moment().isoWeek(),
          };
          this.orderModal = true;
        },
        getVesselVoyages() {
            let voyages = this.containers.map(x => x.vesselVoyage)
            voyages = Array.from(new Set(voyages))
            this.vesselVoyageSet = voyages
        },
        getShippingLines() {
            let lines = this.containers.map(x => x.shippingLineName)
            lines = Array.from(new Set(lines))
            this.shippingLinesSet = lines
        },
        async getContainers() {
            this.loading = true
            let result = await this.$API.getTradingContainers()
            if(result) {
                this.containers = result
                this.loading = false                
                if(!this.selectedTemplate) {
                    this.$nextTick(() => {
                        this.gridApi.autoSizeAllColumns()
                    })
                }
            }
            console.log(JSON.parse(JSON.stringify(this.containers)))
            this.getVesselVoyages()
            this.getShippingLines()
        },
        getColor(status) {
            let state = this.status.find(x => x.name == status)
            if(state) {
                return state.color
            } else {
                return 'grey'
            }
        },
        setCurrentWeek() {
            let currentWeek = moment().isoWeek();
            let currentYear = moment().isoWeekYear();
            this.currentWeek = `${currentYear} - W${currentWeek}`
        },
        showChart() {
            this.showCharts = !this.showCharts
            if(this.showCharts) {
                this.$nextTick(() => {
                    this.initResizeMain()
                    this.initResizeGraphs('.gutter1')
                    this.initResizeGraphs('.gutter2')
                    this.initResizeGraphs('.gutter3')
                })
            }
        },
        handleResizeMain() {
            this.initResizeMain()
        },
        initResizeMain() {
            const resizer = document.querySelector('.gutter');
            if(resizer) {
                const top = document.querySelector('.topSection');
                const bottom = document.querySelector('.bottomSection');
                let x, y, tH, bH, tMin, bMin;
                const mouseDownHandler = (e) => {
                    x = e.clientX;
                    y = e.clientY;
                    let topHeight = window.getComputedStyle(top).height;
                    let bottomHeight = window.getComputedStyle(bottom).height;
                    let bottomMin = window.getComputedStyle(bottom).minHeight;
                    tH = parseInt(topHeight);
                    console.log(tH)
                    bH = parseInt(bottomHeight);
                    tMin = 250;
                    bMin = parseInt(bottomMin);
                    document.addEventListener('mousemove', mouseMoveHandler);
                    document.addEventListener('mouseup', mouseUpHandler);
                }
                const mouseMoveHandler = (e) => {
                    const dy = e.clientY - y;
                    const newTopHeight = tH + dy;
                    const newBottomHeight = bH - dy;
                    let snapHeight = 100;
                    if (newTopHeight < snapHeight) {
                        this.showCharts = false;
                        bottom.style.height = `calc(100vh - 66px - 25px)`;
                        document.removeEventListener('mousemove', mouseMoveHandler);
                        document.removeEventListener('mouseup', mouseUpHandler);
                    } else if (newTopHeight < tMin || newBottomHeight < bMin) {
                        return;
                    } else {
                        top.style.height = `${newTopHeight}px`;
                        bottom.style.height = `${newBottomHeight}px`;
                    }
                }
                const mouseUpHandler = () => {
                    document.removeEventListener('mousemove', mouseMoveHandler);
                    document.removeEventListener('mouseup', mouseUpHandler);
                }
                resizer.addEventListener('mousedown', mouseDownHandler);
            }
        },
        handleResizeGraphs(gutter) {
            this.initResizeGraphs(gutter)
        },
        initResizeGraphs(gutter) {
            const resizer = document.querySelector(gutter);
            if(resizer) {
            let g1, g2, g3, g4, topSection;
            topSection = document.querySelector('.topSection');
            this.graphs[0].enabled ? g1 = document.querySelector('.g1') : g1 = null;
            this.graphs[1].enabled ? g2 = document.querySelector('.g2') : g2 = null;
            this.graphs[2].enabled ? g3 = document.querySelector('.g3') : g3 = null;
            this.graphs[3].enabled ? g4 = document.querySelector('.g4') : g4 = null;
            
            let x, y, g1W, g2W, g3W, g4W, totalWidth;
            const mouseDownHandler = (e) => {
                x = e.clientX;
                y = e.clientY;
                let g1Width = g1 ? window.getComputedStyle(g1).width : 0;
                let g2Width = g2 ? window.getComputedStyle(g2).width : 0;
                let g3Width = g3 ? window.getComputedStyle(g3).width : 0;
                let g4Width = g4 ? window.getComputedStyle(g4).width : 0;
                let topWidth = window.getComputedStyle(topSection).width;
                g1W = parseInt(g1Width);
                g2W = parseInt(g2Width);
                g3W = parseInt(g3Width);
                g4W = parseInt(g4Width);
                totalWidth = parseInt(topWidth);
                document.addEventListener('mousemove', mouseMoveHandler);
                document.addEventListener('mouseup', mouseUpHandler);
            }

            const mouseMoveHandler = (e) => {
                const dx = e.clientX - x;
                requestAnimationFrame(() => {
                    if (gutter === '.gutter1' && g1) {
                        let nextElement = g2 ? g2 : g3 ? g3 : g4 ? g4 : null;
                        let nextWidth = g2 ? g2W : g3 ? g3W : g4 ? g4W : null;
                        if(g1W + dx < 250) { return; }
                        else if(nextWidth + (-dx) < 250) { return; }
                        else {
                            g1.style.width = `${Math.max(g1W + dx, 250)}px`;
                            nextElement.style.width = `${nextWidth + (-dx)}px`;
                        }
                    } else if (gutter === '.gutter2' && g2) {
                        let nextElement = g3 ? g3 : g4 ? g4 : null;
                        let nextWidth = g3 ? g3W : g4 ? g4W : null;
                        if(g2W + dx < 250) { return; }
                        else if(nextWidth + (-dx) < 250) { return; }
                        else {
                            g2.style.width = `${Math.max(g2W + dx, 250)}px`;
                            nextElement.style.width = `${nextWidth + (-dx)}px`;
                        }
                    } else if (gutter === '.gutter3' && g3) {
                        let nextElement = g4 ? g4 : null;
                        let nextWidth = g4 ? g4W : null;
                        if(g3W + dx < 250) { return; }
                        else if(nextWidth + (-dx) < 250) { return; }
                        else {
                            g3.style.width = `${Math.max(g3W + dx, 250)}px`;
                            nextElement.style.width = `${nextWidth + (-dx)}px`;
                        }
                    }
                });
            }
            const mouseUpHandler = () => {
                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
            }
            resizer.addEventListener('mousedown', mouseDownHandler);
            }
        },
    }
}

</script>

<style scoped lang="scss">
// * {
//     --gutters: 0.1rem;
//     --toolBarHeight: 36px;
//     --tabHeight: 30px;
//     --darkLayer: var(--v-greyDarker-base) !important;
// }
@import "~ag-grid-community/styles/ag-grid.css";
@import "../styles/ag-grid-theme-builder.css";

.backgroundMain {
    background-color: var(--v-greyRaised-base) !important;
    width: 100%;
    contain: content;
    overflow-y: hidden;
    overflow-x: auto;
    height: calc(100vh - 36px - 25px);
}
.dullToHoverText {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
}
.dullToHoverText:hover {
    opacity: 0.9;
}
.text {
    font-size: 0.7rem;
    opacity: 0.7;
}
::v-deep .ag-charts-theme-default {
    --ag-charts-background-color: var(--v-greyRaised-base) !important;
}
.topSection {
    display: flex;
    width: 100%;
    max-width: 100%;
    contain: content;
    overflow: hidden;
}
.graphGrid {
    height: 100%;
    min-width: 100px;
    contain: content;
    display: flex;
}
.g1, .g2, .g3, .g4 {
    background: var(--v-greyDarker-base) !important;
}
.vertHandle {
    rotate: 90deg;
    position: absolute;
    cursor: col-resize !important;
}

.graphState {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px; 
    width: 90px; 
    font-size: 16px;
}

.graphState b {
    z-index: 10
}

.graphState ::v-deep .v-icon {
    position: absolute; 
    z-index:5; 
    opacity: 0.1
}
.gutter1, .gutter2, .gutter3 {
    height: 100%;
    width: 1px !important;
    overflow: visible;
    background: var(--v-greyBase-lighten2);
    cursor: col-resize;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gutter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    height: 1px !important;
    background: var(--v-greyBase-lighten2);
}
.handle, .vertHandle {
    display: flex;
    overflow: hidden;
    cursor: row-resize;
    width: 38px;
    height: 10px;
    font-size: 6px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: var(--v-greyBase-lighten2);
    z-index: 999;
}

::v-deep .chart-wrapper {
    height: inherit !important;
    width: inherit !important;
}

::v-deep .chart-wrapper-body {
    height: inherit !important;
}

.handle::after, .vertHandle::after {
  content: 'l l l';
}
.tabsTop {
    width: fit-content;
    height: 30px;
}
.expandPanel {
    background-color: var(--v-greyDarker-base) !important;
    height: 50px;
}

.expandPanel .top, .expandPanel .bottom {
    display: flex;
    align-items: center;
    padding: 4px 4px;
    height: 50%;
}
.expander {
    width: 100%;
    height: 10px;
    background: var(--v-greyDarker-base);
    display: flex;
    align-items: center;
    justify-content: center;
}
.v-tooltip__content {
    padding: 2px 10px;
    font-size: 12px;   
}
.expander:hover {
    cursor: pointer;
    background: var(--v-greyRaised-lighten1) !important;
}
.rightCard {
    visibility: hidden;
    background-color: var(--v-greyRaised-base) !important;
    position: absolute;
    right: calc((350px + var(--toolBarHeight)) * -1);
    max-width: calc(350px + var(--toolBarHeight));
    width: calc(350px + var(--toolBarHeight));
    top: 0;
    height: calc(100vh - 25px);
    box-shadow: -10px 0 10px -5px rgba(0, 0, 0, 0.5);
    padding-right: var(--toolBarHeight);
    z-index: 888;
    transition: right 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.tableHeaderCell:hover, .tableCell:hover {
    background-color: var(--v-greyRaised-lighten1) !important;
}

.tableHeaderCell:hover .sortButton {
    visibility: visible;
}

.sortButton {
    visibility: hidden;
}
.table::-webkit-scrollbar {
  display: none;
}
.resizeSide {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 80%;
    cursor: col-resize;
    background-color: transparent;
    border-radius: 4px;
    z-index: 200;
}
.resizeSide:hover {
    background-color: var(--v-greyBase-lighten2);
}
.tableHeaderCell {
    contain: content;
    cursor: grab;
    overflow-x: hidden;
    align-items: start;
    min-height: 20px !important;
    max-width: 300px !important;
    border-bottom: 1px solid var(--v-greyRaised-lighten2) !important;
}
.menuList .v-list-item{
  font-size: 12px;
  min-height: 30px;
  padding: 0;
}
.menuList ::v-deep .v-icon {
  color: #989898 !important;
}
.rightCard.show {
    right: 0;
    visibility: visible;
}
.v-skeleton-loader.theme--dark ::v-deep .v-skeleton-loader__table-heading {
    background: var(--v-greyRaised-base) !important;
}
.firstRow {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    background-color: var(--v-component-base) !important;
}

.searchBar ::v-deep .v-input__slot {
    height: 32px !important;
    min-height: 32px !important;
}

.rightNav {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--v-component-base) !important;
    width: var(--toolBarHeight);
    min-width: var(--toolBarHeight);
    z-index: 999;
}

.secondRow {
    display: flex;
    width: 100%;
    height: 36px;
    min-height: 36px;
    max-height: 72px;
    padding: 0 2px 0 5px;
    align-items: center;
    background-color: var(--v-greyRaised-lighten1) !important;
}

.innerContent {
  height: 100%;
  width: 100%;
}

.tabsTop ::v-deep .v-tabs .v-tabs-bar, .tabsTop ::v-deep .v-tabs-bar__content, .tabsTop ::v-deep .v-tab{
    background-color: var(--v-greyBase-base) !important;
}


.chipIcon {
    margin-right: 3px;
    font-size: 16px !important;
}
.showHover ::v-deep .pencil {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.showHover:hover ::v-deep .pencil {
    opacity: 1;
}

.listIcon {
    margin-right: 10px;
    font-size: 16px !important;
}

.upIcon {
    rotate: 180deg;
}
.groupBar {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
}
.secondaryGroup {
    padding-left: 30px;
}

.tertiaryGroup {
    padding-left: 60px;
}

.tabIcon {
    margin-right: 3px;
    font-size: 20px !important;
}

.tooltip::before {
  position: absolute;
  content: "";
  height: 0.6em;
  width: 0.6em;
  bottom: -0.2em;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  background: var(--v-greyRaised-lighten1) !important
}
.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.denseSelect:not(.fullW) {
    max-width: 150px;
    min-width: 75px;
    width: fit-content !important;
}
.fullW {
    max-width: 300px;
}
.denseSelect ::v-deep div.v-input__control div.v-input__slot {
    font-size: 14px !important;
    max-height: 30px !important;
    min-height: auto !important;
    display: flex !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    align-items: center !important;
    padding-top: 0 !important;
}
.denseSelect ::v-deep div.v-text-field__slot input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.denseSelect ::v-deep div.v-input__slot::before {
    display: none !important;
    border: none !important;
}

.filterWrap {
    background: var(--v-greyDarker-base) !important;
    padding: 5px 5px;
    margin: 5px 0;
    border-radius: 10px;
}

.switcher {
    scale: 0.9;
    margin-top: 2px;
}
.switcher ::v-deep div.v-input--selection-controls__input {
    margin-right: 0;
}

.ag-grid-theme-builder ::v-deep .ag-side-button-button {
    min-height: calc(var(--ag-grid-size) * 7) !important;
    justify-content: flex-start;
    overflow: hidden;
    gap:5px !important;
    white-space: nowrap;
    height: calc(var(--ag-grid-size) * 7) !important;
    max-height: calc(var(--ag-grid-size) * 10) !important;;
    transition: height 0.5s ease-in-out 0.75s, max-height 0.5s ease-in-out 0.75s;
}

.ag-grid-theme-builder ::v-deep .ag-side-button-button:hover {
    height: 102px !important;
    max-height: calc(var(--ag-grid-size) * 20) !important
}

.ag-grid-theme-builder ::v-deep .ag-side-button-label {
    height: 0px !important;
    transition: height 0.5s ease-in-out 0.75s;
    display: flex;
    overflow: hidden;
}
.ag-grid-theme-builder ::v-deep .ag-side-button-button:hover .ag-side-button-label {
    height: 84px !important;
}

.ag-grid-theme-builder {
    --ag-icon-image-color-picker: url("../../public/icons/view_carousel.svg"); 
    --ag-icon-font-display-color-picker: none;
    --ag-icon-font-color-color-picker: transparent;
}

.ag-grid-theme-builder ::v-deep .ag-icon.ag-icon-color-picker {
    scale: 1.2;
}

</style>