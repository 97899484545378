<template>
    <div class="d-flex justify-center" style="width: 100%;">
        <v-btn small icon color="white" @click="copyItem()"><v-icon small>content_copy</v-icon></v-btn>
        <v-btn small icon color="redPop" @click="deleteItem()"><v-icon small>delete</v-icon></v-btn>
    </div>
        
    
</template>

<script>
export default {
    data: () => ({
        
    }),
    methods: {
        copyItem() {
            this.params.duplicateRow()

        },
        deleteItem() {
            this.params.deleteRow()
        }
    }
}
</script>