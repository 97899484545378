<template>
    <div>
        {{ value?.name ? value.name : '' }}
    </div>
</template>

<script>
export default {
    data: () => ({
        value: null,
    }),
    beforeMount() {
        this.value = this.params.val ?? null
    },
}
</script>