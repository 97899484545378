<template>
    <div class="d-flex" style="height: 100%; width: 100%;">
        <div style="position: absolute; left: 4px; top: 1px; z-index: 1;">
            <v-chip-group :loading="loadingSeasons" mandatory active-class="teal--text" class="seasons" >
              <v-menu bottom offset-y v-if="selectedSeason">
                <template v-slot:activator="{on, attrs}">
                    <v-chip v-bind="attrs" v-on="on" small @click="seasonClick()" @contextmenu="seasonContext($event)">
                        {{ selectedSeason.name ?? 'Select Season'}}
                    </v-chip>
                </template>
                <v-card :background-color="'var(--v-greyDarker-base) !important'" style="min-width: 200px; height: auto; border: 1px solid var(--v-greyRaised-lighten2);">
                    <div style="max-height: 200px; height: auto; overflow-y: scroll; background: var(--v-greyDarker-base) !important;" v-if="availableSeasons.length > 0">
                        <v-list-item :style="season.id == x.id ? {'background-color': 'var(--v-greyBase-base) !important'} : {'background-color':'var(--v-greyDarker-base) !important'}"
                        v-for="(x, index) in availableSeasons" :key="index" @click="getForecast(x)">
                            <v-list-item-action>
                                <v-icon small>calendar_month</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="pa-0">
                                <v-list-item-title>
                                    {{ x.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                    <v-divider style="height: 2px; background-color: var(--v-greyRaised-lighten1);"></v-divider>
                    <v-list dense :color="'var(--v-greyDarker-base) !important'" class="py-1 px-0">
                      <v-list-item @click="addSeason()" >
                        <v-list-item-action class="mr-1">
                          <v-icon small color="primary">add_circle_outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">
                          <v-list-item-title> Add Season </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="seasonModal = true" v-if="availableSeasons.length > 0 && season?.id">
                        <v-list-item-action class="mr-1">
                          <v-icon small color="blue">edit</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">
                          <v-list-item-title> Edit Season </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="shiftSeasonModal=true" v-if="availableSeasons.length > 0 && season?.id">
                        <v-list-item-action class="mr-1">
                          <v-icon small color="teal">refresh</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">
                          <v-list-item-title> Shift Season </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="season && season.id && availableSeasons.length > 0" @click="confirmDelete = true">
                        <v-list-item-action class="mr-1">
                          <v-icon small color="red">delete</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">
                          <v-list-item-title> Delete Season </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                </v-card>
              </v-menu>
            </v-chip-group>
        </div>
        <div class="firstCol d-flex" :style="sideNav ? {} : {'width': '100% !important'}">
            <v-btn-toggle mandatory multiple :light="!$store.state.darkMode" outlined v-model="toggleView" rounded  class="buttonSwitch">
                <v-btn small outlined class="px-2" style="width: 174px;" color="info">
                    <v-icon small class="mr-1" :color="toggleView.includes(0) ? 'info' : 'primaryText'">attach_money</v-icon>
                    <span style="text-transform: none; color: var(--v-primaryText-base)">Customer Forecasts</span>
                </v-btn>
                <v-btn small outlined class="px-2" style="width: 174px;" :color="'var(--v-tertiary-lighten3)'">
                    <v-icon class="mr-1" small :color="toggleView.includes(1) ? 'var(--v-success-lighten1)' : 'primaryText'">account_balance</v-icon>
                    <span style="text-transform: none; color: var(--v-primaryText-base)">Supplier Forecasts</span>
                </v-btn>
            </v-btn-toggle>
            <div style="height: 100%; width: 100%;" :style="!toggleView.includes(0) ? {'display': 'none'} : ''">
                <div style="background: var(--v-greyRaised-lighten1); height:30px; width: 100%; border-right: solid 1px var(--v-greyDarker-base)" ></div>
                <div style="background: var(--v-greyRaised-lighten1); height:40px; width: 100%; border-right: solid 1px var(--v-greyDarker-base)" class="d-flex align-center px-1">
                    <v-chip style="display: grid" class="px-2">
                        <v-autocomplete :menu-props="{offsetY: true}" placeholder="Select Customer" class="mb-1 textFieldSearch" :class="selectedCustomer ? 'hideInput' : ''" hide-details rounded clearable dense :items="customers" v-model="selectedCustomer" :loading="loadingOrganisations" item-text="name" item-value="name">
                            <template v-slot:item="{ item }">
                                <div class="d-flex align-center">
                                    <v-avatar size="24" :color="(item.icon || item.logo) ? 'white' : 'var(--v-greyBase-darken1)'" class="mr-2">
                                        <v-img v-if="item.icon || item.logo" :src="item.icon ?? item.logo" contain></v-img>
                                        <span v-else-if="item.name">{{ item.name.toUpperCase().charAt(0) }}</span>
                                    </v-avatar>
                                    <span style="font-size: 12px;">{{ item.alias ?? item.name ?? '' }}</span>
                                </div>
                            </template>
                            <template v-slot:selection="{ item }">
                                <div class="d-flex align-center">
                                    <v-avatar size="24" :color="(item.icon || item.logo) ? 'white' : 'var(--v-greyBase-darken1)'" class="mr-2">
                                        <v-img v-if="item.icon || item.logo" :src="item.icon ?? item.logo" contain></v-img>
                                        <span v-else-if="item.name">{{ item.name.toUpperCase().charAt(0) }}</span>
                                    </v-avatar>
                                    <span style="font-size: 12px;">{{ item.alias ?? item.name ?? '' }}</span>
                                </div>
                            </template>
                        </v-autocomplete>
                    </v-chip>
                    <v-spacer></v-spacer>
                    <v-chip style="display: grid" class="px-2">
                        <v-chip class="px-1" small dense color="edit" @click="forecastModalChange('CUSTOMER')"><v-icon small>add_circle_outline</v-icon></v-chip>
                        <v-icon style="font-size: 16px !important;" color="grey" class="ml-1">search</v-icon>
                        <v-text-field placeholder="Search" class="mb-1 textFieldSearch searchInput" hide-details rounded clearable dense></v-text-field>
                    </v-chip>
                </div>
                <div style="background: var(--v-greyRaised-lighten1); height:calc((100vh - 100px - 25px)); width: 100%">
                    <ag-grid-vue style="width: auto; height: 100%;" 
                        :columnDefs="customerHeaders"
                        :rowData="customerItems" 
                        :defaultColDef="defaultColOverview"
                        :hideOverlay="loadingForecast"
                        :loading="loadingForecast"
                        :loadingOverlayComponent="loadingOverlayComponent"
                        @first-data-rendered="onFirstDataRendered"
                    />
                </div>
            </div>
            <div style=" height: 100%; width: 100%;" :style="!toggleView.includes(1) ? {'display': 'none'} : ''">
                <div style="background: var(--v-greyRaised-lighten1); height:30px; width: 100%; border-left: solid 1px var(--v-greyDarker-base)">
                    
                </div>
                <div style="background: var(--v-greyRaised-lighten1); height:40px; width: 100%; border-left: solid 1px var(--v-greyDarker-base)" class="d-flex align-center px-1">
                    <v-chip style="display: grid" class="px-2">
                        <v-autocomplete :menu-props="{offsetY: true}" placeholder="Select Supplier" class="mb-1 textFieldSearch" :class="selectedSupplier ? 'hideInput' : ''" hide-details rounded clearable dense :items="suppliers" v-model="selectedSupplier" :loading="loadingOrganisations" item-text="name" item-value="name">
                            <template v-slot:item="{ item }">
                                <div class="d-flex align-center">
                                    <v-avatar size="24" :color="(item.icon || item.logo) ? 'white' : 'var(--v-greyBase-darken1)'" class="mr-2">
                                        <v-img v-if="item.icon || item.logo" :src="item.icon ?? item.logo" contain></v-img>
                                        <span v-else-if="item.name">{{ item.name.toUpperCase().charAt(0) }}</span>
                                    </v-avatar>
                                    <span style="font-size: 12px;">{{ item.alias ?? item.name ?? '' }}</span>
                                </div>
                            </template>
                            <template v-slot:selection="{ item }">
                                <div class="d-flex align-center">
                                    <v-avatar size="24" :color="(item.icon || item.logo) ? 'white' : 'var(--v-greyBase-darken1)'" class="mr-2">
                                        <v-img v-if="item.icon || item.logo" :src="item.icon ?? item.logo" contain></v-img>
                                        <span v-else-if="item.name">{{ item.name.toUpperCase().charAt(0) }}</span>
                                    </v-avatar>
                                    <span style="font-size: 12px;">{{ item.alias ?? item.name ?? '' }}</span>
                                </div>
                            </template>
                        </v-autocomplete>
                    </v-chip>
                    <v-spacer></v-spacer>
                    <v-chip style="display: grid" class="px-2">
                        <v-chip class="px-1" small dense color="tertiary" @click="forecastModalChange('SUPPLIER')"><v-icon small>add_circle_outline</v-icon></v-chip>
                        <v-icon style="font-size: 16px !important;" color="grey" class="ml-1">search</v-icon>
                        <v-text-field placeholder="Search" class="mb-1 textFieldSearch searchInput" hide-details rounded clearable dense></v-text-field>
                    </v-chip>
                </div>
                <div style="background: var(--v-greyRaised-lighten1); height:calc((100vh - 100px - 25px)); width: 100%">
                    <ag-grid-vue style="width: auto; height: 100%;" 
                        :columnDefs="supplierHeaders"
                        :rowData="supplierItems"
                        :defaultColDef="defaultColOverview"
                        :hideOverlay="loadingForecast"
                        :loading="loadingForecast"
                        :loadingOverlayComponent="loadingOverlayComponent"
                        @first-data-rendered="onFirstDataRendered"
                    />
                </div>
            </div>
        </div>
        <div :class="forecastModal ? 'forecastModal active' : 'forecastModal' " style="height: 100%; width: 100%; z-index: 3; position: absolute;" >
          <v-card style="height: 100%; width: 100%; background: var(--v-greyDarker-lighten1) !important; contain: content;">
            <v-subheader class="px-2" style="height: 30px">
              Create {{ forecastType.charAt(0) + forecastType.substring(1).toLowerCase() }} Forecast
              <v-spacer></v-spacer>
              <v-tooltip bottom offset-y v-if="saveDisabled.messages.length > 0">
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" small outlined style="border: none;" color="redPop">
                    <v-icon class="mr-1" color="redPop">error_outline</v-icon>                                      
                    {{ saveDisabled.messages.length }}
                  </v-chip>
                </template>
                  <div v-for="(message, idx) in saveDisabled.messages" :key="idx" style="font-size: 10px; display: list-item;">
                    {{ message }}
                  </div>
              </v-tooltip>
              <v-btn small icon color="edit" :disabled="saveDisabled.disabled" @click="saveDialog = true">
                <v-icon>save</v-icon>
              </v-btn>
              <v-btn small icon color="redPop" @click="forecastModal = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <v-row no-gutters id="firstRow">
              <v-col cols="12" sm="12" md="3" lg="3" class="px-2 py-1">
                <!-- FORECAST DETAILS -->
                <v-subheader style="height: 30px;" class="px-2">
                  Forecast Details
                  <v-spacer></v-spacer>
                  <!-- <v-btn small outlined color="edit"><v-icon small>bookmark</v-icon>Templates</v-btn> -->
                </v-subheader>
                <v-divider class="mb-4"></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="12" lg="12" class="pb-4 px-1">
                    <v-autocomplete v-if="forecastType == 'CUSTOMER'" class="formInputs" prepend-inner-icon="apartment" item-text="name" item-value="id" v-model="forecastSettings.customerId" outlined dense hide-details label="Customer" :items="customers">
                      <template v-slot:item="{ item }">
                            <div class="d-flex align-center">
                                <v-avatar size="24" :color="(item.icon || item.logo) ? 'white' : 'var(--v-greyBase-darken1)'" class="mr-2">
                                    <v-img v-if="item.icon || item.logo" :src="item.icon ?? item.logo" contain></v-img>
                                    <span v-else-if="item.name">{{ item.name.toUpperCase().charAt(0) }}</span>
                                </v-avatar>
                                <span style="font-size: 12px;">{{ item.alias ?? item.name ?? '' }}</span>
                            </div>
                        </template>
                        <template v-slot:selection="{ item }">
                            <div style="display: inline-flex; align-items: center; overflow-y: hidden; text-overflow: ellipsis; max-width: fit-content; text-wrap: nowrap;">
                                <v-avatar size="24" :color="(item.icon || item.logo) ? 'white' : 'var(--v-greyBase-darken1)'" class="mr-2">
                                    <v-img v-if="item.icon || item.logo" :src="item.icon ?? item.logo" contain></v-img>
                                    <span v-else-if="item.name">{{ item.name.toUpperCase().charAt(0) }}</span>
                                </v-avatar>
                                <span style="font-size: 12px;">{{ item.alias ?? item.name ?? '' }}</span>
                            </div>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete v-if="forecastType == 'SUPPLIER'" class="formInputs" prepend-inner-icon="apartment" item-text="name" item-value="id" v-model="forecastSettings.supplierId" outlined dense hide-details label="Supplier" :items="suppliers">
                      <template v-slot:item="{ item }">
                            <div class="d-flex align-center">
                                <v-avatar size="24" :color="(item.icon || item.logo) ? 'white' : 'var(--v-greyBase-darken1)'" class="mr-2">
                                    <v-img v-if="item.icon || item.logo" :src="item.icon ?? item.logo" contain></v-img>
                                    <span v-else-if="item.name">{{ item.name.toUpperCase().charAt(0) }}</span>
                                </v-avatar>
                                <span style="font-size: 12px;">{{ item.alias ?? item.name ?? '' }}</span>
                            </div>
                        </template>
                        <template v-slot:selection="{ item }">
                            <div style="display: inline-flex; align-items: center; overflow-y: hidden; text-overflow: ellipsis; max-width: fit-content; text-wrap: nowrap;">
                                <v-avatar size="24" :color="(item.icon || item.logo) ? 'white' : 'var(--v-greyBase-darken1)'" class="mr-2">
                                    <v-img v-if="item.icon || item.logo" :src="item.icon ?? item.logo" contain></v-img>
                                    <span v-else-if="item.name">{{ item.name.toUpperCase().charAt(0) }}</span>
                                </v-avatar>
                                <span style="font-size: 12px;">{{ item.alias ?? item.name ?? '' }}</span>
                            </div>
                        </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" class="pb-4 px-1" v-if="forecastType == 'CUSTOMER'">
                    <v-autocomplete :menu-props="{offsetY: true}" class="formInputs" outlined dense hide-details label="Target Country*" :loading="loadingCountries" v-model="forecastSettings.destinationCountryId" :items="countries.data" item-text="name" item-value="id">
                      <template v-slot:prepend-inner>
                        <span class="material-symbols-outlined"> globe_asia </span>
                      </template>
                      <template v-slot:selection="data">
                        <v-avatar size="24" class="mr-2" v-if="data.item">
                          <v-img v-if="data.item.iso2" contain :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`" > </v-img>
                        </v-avatar>
                        <span v-if="data.item"> {{ data.item.name }} </span>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <v-img contain v-if="data.item.iso2" :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`" > </v-img>
                        </v-list-item-avatar>
                        <v-list-item-content class="pl-2">
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="6" class="pb-1 px-1">
                    <v-select :menu-props="{offsetY: true}" class="formInputs"  prepend-inner-icon="calendar_month" outlined dense hide-details label="Season*" v-model="forecastSettings.seasonId" :items="availableSeasons" item-text="name" item-value="id"></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="6" class="pb-1 px-1">
                    <v-select v-model="forecastSettings.weekType" :menu-props="{offsetY: true}"  class="formInputs" prepend-inner-icon="date_range" outlined dense hide-details label="Week Type" :items="['Pack Week', 'ETD Week', 'ETA Week']" ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <!-- FORECAST WEEKS -->
              <v-col cols="12" sm="12" md="3" lg="3" class="px-2 py-1">
                <v-subheader style="height: 30px;" class="px-2">Forecast Weeks </v-subheader>
                <v-divider class="mb-4"></v-divider>
                <div class="d-flex justify-center align-center">
                  <v-menu v-model="startWeekMenu" offset-y :close-on-content-click="false" :disabled="!selectedSeason || !selectedSeason.start || !selectedSeason.end">
                    <template v-slot:activator="{on, attrs}">
                      <v-chip v-on="on" v-bind="attrs" small color="tertiary" style="border-radius: 6px;" class="px-2"><v-icon small class="mr-1">today</v-icon>{{forecastSettings.startWeek ? forecastSettings.startWeek : forecastWeekRange == 1 ? 'Start Week' : 'Select Week'}}</v-chip>
                    </template>
                    <v-card v-if="selectedSeason?.start && selectedSeason?.end">
                      <v-date-picker 
                        first-day-of-week="1"
                        v-model="forecastSettings.startWeek" 
                        show-week 
                        locale-first-day-of-year="4"
                        :min="selectedSeason.start" 
                        :max="selectedSeason.end"
                        @change="popOpenWeeks('start')"
                      ></v-date-picker>
                      <v-card-actions class="justify-end">
                        <v-btn small color="edit" @click="startWeekMenu = false">Ok</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-icon class="mx-3" v-if="forecastWeekRange == 1">arrow_forward</v-icon>
                  <v-menu v-if="forecastWeekRange == 1" v-model="endWeekMenu" offset-y :close-on-content-click="false" :disabled="!selectedSeason || !selectedSeason.start || !selectedSeason.end">
                    <template v-slot:activator="{on, attrs}">
                      <v-chip v-on="on" v-bind="attrs" small color="tertiary" style="border-radius: 6px;" class="px-2"><v-icon small class="mr-1">event</v-icon>{{forecastSettings.endWeek ? forecastSettings.endWeek : 'End Week'}}</v-chip>
                    </template>
                    <v-card v-if="selectedSeason?.start && selectedSeason?.end">
                      <v-date-picker 
                        first-day-of-week="1"
                        v-model="forecastSettings.endWeek" 
                        locale-first-day-of-year="4"
                        show-week 
                        :min="selectedSeason.start" 
                        :max="selectedSeason.end"
                        @change="popOpenWeeks('end')"
                      ></v-date-picker>
                      <v-card-actions class="justify-end">
                        <v-btn small color="edit" @click="endWeekMenu = false">Ok</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </div>
                <div class="d-flex align-center justify-center pa-0" style="width: 100%;margin-top: 10px !important">
                    <span style="font-size: 10px; opacity: 0.8;">Included Weeks</span>
                </div>
                <v-divider></v-divider>
                <div style="width: 100%;">
                  <div class="d-flex align-center justify-center py-2" style="flex-wrap: wrap; gap: 3px; width: 100%;" v-if="getWeeksBetween.length > 0" >
                    <v-chip v-for="(week, index) in getWeeksBetween" :key="index" x-small class="px-1" :color="forecastSettings.excludeWeeks?.includes(week) ? '' : 'edit'" @click="includeOrExclude(week)">{{ week }}</v-chip>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3" class="px-2 py-1">
                <!-- <v-subheader style="height: 30px;" class="px-2">
                  Supply
                </v-subheader>
                <v-divider></v-divider> -->

              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3" class="px-2 py-1" v-if="forecastType == 'CUSTOMER'">
                <v-card flat style="border-radius: 8px; width: 100%; height: 100%; background: var(--v-greyRaised-lighten2) !important;">
                  <v-subheader style="height: 30px;" class="px-2">
                    Shipment Profile
                    <v-spacer></v-spacer>
                    <v-btn icon plain v-if="forecastSettings.consigneeProfileId" @click="shipmentProfileDialog = true"><v-icon style="rotate: 45deg;">swap_horiz</v-icon></v-btn>
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-row no-gutters v-if="forecastSettings.consigneeProfileId">
                    <v-col cols="12" sm="12" md="6" lg="6" class="pl-2 pr-1">
                      <v-subheader style="height: 20px; font-size: 12px;" class="px-1">
                        Parties
                      </v-subheader>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item v-if="forecastSettings.consigneeProfile.shipper" class="mb-2">
                        <v-list-item-action style="min-width: 24px !important; width: 24px !important" class="d-flex align-center">
                          <v-avatar size="20" :color="(forecastSettings.consigneeProfile.shipper.icon) ? 'white' : 'var(--v-greyBase-darken1)'" >
                              <v-img v-if="forecastSettings.consigneeProfile.shipper.icon" :src="forecastSettings.consigneeProfile.shipper.icon" contain></v-img>
                              <span v-else-if="forecastSettings.consigneeProfile.shipper.alias">{{ forecastSettings.consigneeProfile.shipper.alias.toUpperCase().charAt(0) }}</span>
                              <span v-else-if="forecastSettings.consigneeProfile.shipper.name">{{ forecastSettings.consigneeProfile.shipper.name.toUpperCase().charAt(0) }}</span>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content class="pl-2" >
                          <v-list-item-title>{{ forecastSettings.consigneeProfile.shipper.alias ?? forecastSettings.consigneeProfile.shipper.name }}</v-list-item-title>
                          <v-list-item-subtitle v-if="forecastSettings.consigneeProfile.onBehalfShipper">
                            Shipper on behalf of {{ forecastSettings.consigneeProfile.onBehalfShipper.alias ?? forecastSettings.consigneeProfile.onBehalfShipper.name }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else>
                              Shipper
                            </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="forecastSettings.consigneeProfile.consignee" class="mb-2">
                        <v-list-item-action style="min-width: 24px !important; width: 24px !important" class="d-flex align-center">
                          <v-avatar size="20" :color="(forecastSettings.consigneeProfile.consignee.icon) ? 'white' : 'var(--v-greyBase-darken1)'" >
                            <v-img v-if="forecastSettings.consigneeProfile.consignee.icon" :src="forecastSettings.consigneeProfile.consignee.icon" contain></v-img>
                            <span v-else-if="forecastSettings.consigneeProfile.consignee.alias">{{ forecastSettings.consigneeProfile.consignee.alias.toUpperCase().charAt(0) }}</span>
                            <span v-else-if="forecastSettings.consigneeProfile.consignee.name">{{ forecastSettings.consigneeProfile.consignee.name.toUpperCase().charAt(0) }}</span>
                        </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content class="pl-2" >
                          <v-list-item-title>{{ forecastSettings.consigneeProfile.consignee.alias ?? forecastSettings.consigneeProfile.consignee.name }}</v-list-item-title>
                          <v-list-item-subtitle v-if="forecastSettings.consigneeProfile.onBehalfConsignee" >
                            Consignee on behalf of {{ forecastSettings.consigneeProfile.onBehalfConsignee.alias ?? forecastSettings.consigneeProfile.onBehalfConsignee.name }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else>
                            Consignee
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="forecastSettings.consigneeProfile.forwarder" class="mb-2">
                        <v-list-item-action style="min-width: 24px !important; width: 24px !important" class="d-flex align-center">
                          <v-avatar size="20" :color="(forecastSettings.consigneeProfile.forwarder.icon) ? 'white' : 'var(--v-greyBase-darken1)'" >
                            <v-img v-if="forecastSettings.consigneeProfile.forwarder.icon" :src="forecastSettings.consigneeProfile.forwarder.icon" contain></v-img>
                            <span v-else-if="forecastSettings.consigneeProfile.forwarder.alias">{{ forecastSettings.consigneeProfile.forwarder.alias.toUpperCase().charAt(0) }}</span>
                            <span v-else-if="forecastSettings.consigneeProfile.forwarder.name">{{ forecastSettings.consigneeProfile.forwarder.name.toUpperCase().charAt(0) }}</span>
                        </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content class="pl-2" >
                          <v-list-item-title>{{ forecastSettings.consigneeProfile.forwarder.alias ?? forecastSettings.consigneeProfile.forwarder.name }}</v-list-item-title>
                          <v-list-item-subtitle v-if="forecastSettings.consigneeProfile.onBehalfForwarder">
                            Forwarder on behalf of {{ forecastSettings.consigneeProfile.onBehalfForwarder.alias ?? forecastSettings.consigneeProfile.onBehalfForwarder.name }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else>
                            Forwarder
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" class="pl-1 pr-2">
                      <v-subheader style="height: 20px; font-size: 12px;" class="px-1">
                        Settings
                      </v-subheader>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item v-if="forecastSettings.consigneeProfile.originCountry" class="mb-2">
                        <v-list-item-action class="d-flex align-center">
                          <v-avatar size="24">
                            <v-img contain v-if=" forecastSettings.consigneeProfile.originCountry && forecastSettings.consigneeProfile.originCountry.iso2 " :src="`https://cdn.loglive.io/flags/4x3/${forecastSettings.consigneeProfile.originCountry.iso2.toLowerCase()}.svg`" ></v-img>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content class="pl-0">
                          <v-list-item-title>{{ forecastSettings.consigneeProfile.originCountry.name }}</v-list-item-title>
                          <v-list-item-subtitle>
                            Origin Country
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="forecastSettings.consigneeProfile.destinationCountry" class="mb-2" >
                        <v-list-item-action class="d-flex align-center">
                          <v-avatar size="24">
                            <v-img contain v-if=" forecastSettings.consigneeProfile.destinationCountry && forecastSettings.consigneeProfile.destinationCountry.iso2 " :src="`https://cdn.loglive.io/flags/4x3/${forecastSettings.consigneeProfile.destinationCountry.iso2.toLowerCase()}.svg`"/>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content class="pl-0">
                          <v-list-item-title>{{ forecastSettings.consigneeProfile.destinationCountry.name }}</v-list-item-title>
                          <v-list-item-subtitle>
                            Destination Country
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="forecastSettings.consigneeProfile.finalDestinationCity" class="mb-2">
                        <v-list-item-action class="d-flex align-center">
                          <v-avatar size="24">
                            <v-img contain v-if=" forecastSettings.consigneeProfile.finalDestinationValue " :src="`https://cdn.loglive.io/flags/4x3/${forecastSettings.consigneeProfile.finalDestinationValue .substring(0, 2) .toLowerCase()}.svg`" ></v-img>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content class="pl-0">
                          <v-list-item-title>{{ forecastSettings.consigneeProfile.finalDestinationCity }}</v-list-item-title>
                          <v-list-item-subtitle>
                            Final Destination
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-card :disabled="!forecastSettings.customerId" @click="shipmentProfileDialog = true" flat style="border-radius: 0 0 8px 8px; width:100%; height: calc(100% - 30px); background: transparent !important;" v-else class="d-flex align-center justify-center">
                    <v-icon x-large v-if="forecastSettings.customerId">add</v-icon>
                    <span v-else style="font-size: 14px">Select a Customer</span>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
            <v-divider></v-divider>
                <div class="px-2" style="contain: content; overflow-y: auto; contain: content; " :style="{height: secondRowHeight}">
                  <v-card tile style="min-height: 100px; background: var(--v-greyDarker-lighten1) !important;" flat class="px-2 pb-2 mt-1" v-for="(container, idx) in items" :key="idx">
                    <v-subheader class="px-0" style="height: 30px; contain: content">
                      Group {{ idx + 1 }}
                      <v-spacer></v-spacer>
                      <span v-if="!validGroup(container)" style="color: var(--v-redPop-base); position: absolute; left: 50%; transform: translate(-50%)">
                      The quantity of the contents in this group does not match the container capacity
                      </span>
                      <v-chip small outlined dense v-if="container.containerised && groupTotals(container).totalContainers" :color="validGroup(container) ? 'green':'redPop'" :style="validGroup(container) ? {border: 'none'} : {}">
                        <v-icon v-if="validGroup(container)" x-small left>check</v-icon>
                        <v-icon x-small left v-else>warning</v-icon>
                        {{ groupTotals(container).totalContainers * container.containerMultiplier }} {{'Container' + (groupTotals(container).totalContainers > 1 || container.containerMultiplier > 1 ? 's' : '')}}
                      </v-chip>
                      <v-chip small outlined dense v-if="container.palletised && groupTotals(container).totalPallets" class="ml-1" style="border: none;">
                        <v-icon x-small left>widgets</v-icon>
                        {{ groupTotals(container).totalPallets * container.containerMultiplier }} Pallets
                      </v-chip>
                    </v-subheader>
                    <div style="display: flex">
                      <div style="display: flex; align-items: center;" >
                        <span style="font-size: 12px; opacity: 0.7" class="mr-3">Containerised</span>
                          <v-spacer></v-spacer>
                          <v-switch class="ma-0" dense small hide-details v-model="container.containerised"></v-switch>
                      </div>
                      <div style="display: flex; align-items: center;" >
                        <span style="font-size: 12px; opacity: 0.7" class="mx-3">Palletised</span>
                          <v-spacer></v-spacer>
                          <v-switch class="ma-0" dense small hide-details v-model="container.palletised" @change="headersUpdate(container, idx)"></v-switch>
                      </div>
                      <div style="display: flex; align-items: center;" v-if="container.containerised">
                        <div style="font-size: 12px; opacity: 0.7;" class="mx-3">Containers</div>
                          <el-input-number
                          style="width: 100px; height: 22px;"
                          :value="container.containerMultiplier"
                          controls-position="right"
                          size="mini"
                          :min="1"
                          :max="100"
                          class="numInput"
                          v-model="container.containerMultiplier"
                          ></el-input-number>
                      </div>
                      <div style="display: flex; align-items: center;" v-if="container.containerised && container.palletised">
                        <div style="font-size: 12px; opacity: 0.7;" class="mx-3">Pallets Per Container</div>
                          <el-input-number
                          style="width: 100px; height: 22px;"
                          :value="container.palletPerContainer"
                          size="mini"
                          controls-position="right"
                          class="numInput"
                          :min="0"
                          v-model="container.palletPerContainer"
                          ></el-input-number>
                      </div>
                    </div>

                    <ag-grid-vue style="width: 100%; min-height: 50px;" :style="{height: `${((container.orderCargoItems.length) * 25) + 56}px`}"
                      class="ag-grid-theme-builder"
                      :columnDefs="cargoHeaders"
                      :defaultColDef="defaultColForecast"
                      :rowData="container.orderCargoItems"
                      :postProcessPopup="postProcessPopup"
                      :popupParent="popUpParent"
                      :enableRangeSelection="true"
                      :undoRedoCellEditing="true"
                      :undoRedoCellEditingLimit="25"
                      :stopEditingWhenCellsLoseFocus="true"
                      :suppressAggFuncInHeader="true"
                      @first-data-rendered="onFirstDataRendered"
                      @grid-ready="onGridReady($event, idx)"
                      @cellValueChanged="onCellValueChanged($event, idx)"
                      :key="gridKey"
                    ></ag-grid-vue>
                    <v-card style="width: calc(100% - 2px); height: 25px; background: var(--v-greyRaised-lighten2) !important; margin-left: 1px;" tile class="d-flex align-center justify-center py-0" @click="addLineItem(idx)">
                      <v-icon color="primary">add_circle_outline</v-icon>
                    </v-card>
                  </v-card>
                  <v-card style="width: 100%; background: transparent !important;" flat class="d-flex align-center justify-center py-2" >
                    <v-chip @click="addLineItem(null, true)" small outlined style="border: none;">Add Group</v-chip>
                  </v-card>
                </div>
                <div style="height: 30px; background: var(--v-greyDarker-base); width: 100%; position: fixed; bottom: 25px; " class="d-flex justify-end align-center px-2">
                  <span style="font-size: 12px; opacity: 0.8" v-if="totalContainers.totalPerWeek > 0">{{ totalContainers.totalPerWeek }} Containers per Week</span>
                  <span class="mx-2" style="font-size: 12px; opacity: 0.8" v-if="totalContainers.totalPerWeek > 0">|</span>
                  <span style="font-size: 12px; opacity: 0.8" v-if="totalPallets.totalPerWeek > 0">{{totalPallets.totalPerWeek}} Pallets per Week</span>
                  <span class="mx-2" style="font-size: 12px; opacity: 0.8" v-if="totalPallets.totalPerWeek > 0">|</span>
                  <span style="font-size: 12px; opacity: 0.8" v-if="totalUnits.totalPerWeek > 0">{{totalUnits.totalPerWeek}} Units Per Week</span>
                  <span class="mx-2" style="font-size: 12px; opacity: 0.8" v-if="totalUnits.totalPerWeek > 0">|</span>
                  <span style="font-size: 12px; opacity: 0.8" v-if="totalContainers.total > 0"><b>{{totalContainers.total}} Containers</b></span>
                  <span class="mx-2" style="font-size: 12px; opacity: 0.8" v-if="totalContainers.total > 0">|</span>
                  <span style="font-size: 12px; opacity: 0.8" v-if="totalPallets.total > 0"><b>{{totalPallets.total}} Pallets</b></span>
                  <span class="mx-2" style="font-size: 12px; opacity: 0.8" v-if="totalPallets.total > 0">|</span>
                  <span style="font-size: 12px; opacity: 0.8" v-if="totalUnits.total > 0"><b>{{totalUnits.total}} Units</b></span>
                  <!-- <span class="mr-2" style="font-size: 12px; opacity: 0.8">422400 Cartons</span> -->
                </div>
          </v-card>
        </div>
        <div class="secondCol" v-if="sideNav">
            <div style="width: 100%; height: inherit;">
                <div style="height: auto; width: 100%;" class="d-flex align-center justify-center py-2">
                    <div style="height: 200px; width: 200px; border-radius: 50%; background: var(--v-primary-base);">
                        
                    </div>
                </div>
                <div style="height: auto; width: 100%;" class="d-flex align-center justify-center py-2">
                    <div style="height: 200px; width: 200px; border-radius: 50%; background: var(--v-redPop-base);">

                    </div>
                </div>
            </div>
        </div>
        <!-- Add Season -- ADD SEASON --  -->
        <v-dialog v-model="seasonModal" width="350px" :fullscreen="$vuetify.breakpoint.mobile" >
          <v-card class="pb-2">
            <v-subheader>
              Add Season
              <v-spacer></v-spacer>
              <v-btn small icon :disabled="!season.name || !season.start || !season.end" :loading="savingSeason" @click="saveSeason" >
                <v-icon color="secondary">save</v-icon>
              </v-btn>
              <v-btn small icon @click="seasonModal = false" class="ml-1">
                <v-icon color="redPop">close</v-icon>
              </v-btn> 
            </v-subheader>
            <div class="pa-2" style="max-width: 300px; margin: 0 auto;">
              <v-text-field
                label="Name*"
                v-model="season.name"
                autofocus
                outlined
                dense
                clearable
                hide-details
              ></v-text-field>
              <v-autocomplete
                class="mt-2"
                hide-details
                ref="productSelect"
                hide-no-data
                hide-selected
                label="Product*"
                dense
                outlined
                :items="filterProducts"
                clearable
                item-text="name"
                item-value="id"
                :menu-props="{ closeOnContentClick: true }"
                v-model="season.productId"
                :loading="loadingProduct"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.type }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      v-if="data.item.parentProduct"
                      style="font-size: 12px"
                    >
                      Parent: {{ data.item.parentProduct.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </div>
            <div class="d-flex py-2" style="max-width: 300px; margin: 0 auto;">
              <v-list-item>
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" class="text-left">
                      <v-list-item-action>
                        <v-icon :color="season.start ? 'success' : 'secondary'"
                          >today</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="season.start">
                          {{ season.start }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          Start Date
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-date-picker
                    v-model="season.start"
                    :max="season.end"
                    no-title
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>
              </v-list-item>
              <v-list-item>
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" class="text-left">
                      <v-list-item-action>
                        <v-icon :color="season.end ? 'success' : 'secondary'"
                          >today</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="season.end">
                          {{ season.end }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle> End Date </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-date-picker
                    v-model="season.end"
                    :min="season.start"
                    no-title
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>
              </v-list-item>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="shiftSeasonModal" width="350px" >
          <v-card v-if="season">
            <v-subheader>
              {{ season.name }}
              <v-spacer></v-spacer>
              <v-btn color="blue" :disabled="weekAdjustment == 0" icon small :loading="shiftingSeason" @click="shiftSeason()">
                <v-icon color="secondary">save</v-icon>
              </v-btn>
              <v-btn small icon @click="shiftSeasonModal = false" class="ml-1">
                <v-icon color="redPop">close</v-icon>
              </v-btn> 
            </v-subheader>
            <div class="px-2 pb-2">
              <p style="font-size: 14px; text-align:center;" >
               How many weeks would you like to adjust the season by:
              </p>
              <div class="d-flex align-center justify-center pb-2">
                <div>
                  <v-btn fab x-small @click="weekAdjustment -= 1" color="redPop">
                    <v-icon>remove</v-icon>
                  </v-btn>
                </div>
                <div class="px-3 d-flex align-center justify-center" style="font-size: 16px;"><b>{{ weekAdjustment > 0 ? '+' + weekAdjustment : weekAdjustment  }}</b></div>
                <div>
                  <v-btn fab x-small @click="weekAdjustment += 1" color="edit">
                    <v-icon>add</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="confirmDelete" width="350px" persistent :fullscreen="$vuetify.breakpoint.mobile" >
          <v-card>
            <div class="pa-2">
              <p style="font-size: 14px; text-align:center;">
                Are you sure you would like <b>delete this season</b> and all of its
                forecasts?
              </p>
              <div class="d-flex align-center justify-center pb-2">
                <v-btn color="red" class="mx-2" small @click="confirmDelete = false" >No</v-btn >
                <v-btn color="success" class="mx-2" text small :loading="deletingSeason" @click="deleteSeason()" >Yes</v-btn >
              </div>
          </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="shipmentProfileDialog" width="90vw" :fullscreen="$vuetify.breakpoint.mobile" persistent >
          <v-card v-if="shipmentProfileDialog">
            <v-card-title>
              Search Shipment Profiles
              <v-spacer></v-spacer>
              <v-btn icon @click="shipmentProfileDialog = false" color="redPop"><v-icon>close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
              <v-row justify="end">
                <v-col cols="12" sm="4">
                  <v-text-field placeholder="Search" autofocus prepend-inner-icon="search" v-model="searchProfiles" outlined dense clearable hide-details ></v-text-field>
                </v-col>
              </v-row>
              <v-data-table
                id="profile-info"
                style="cursor: pointer; font-size: 12px"
                :items="filteredProfiles"
                dense
                :loading="loadingShipmentProfiles"
                :headers="shipmentProfileHeaders"
                fixed-header
                :search="searchProfiles"
                @click:row="setShipmentProfile"
                height="70vh"
              >
                <template v-slot:[`item.systemReference`]="{ item }">
                  <b>{{ item.systemReference }}</b>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    v-if="item.status == 'Draft'"
                    color="blue"
                    class="white--text"
                    small
                    >Draft</v-chip
                  >
                  <v-chip
                    v-else-if="item.status == 'Approved'"
                    color="success"
                    class="white--text"
                    small
                    >Approved</v-chip
                  >
                </template>
                <template v-slot:[`item.shipper.name`]="{ item }">
                  <span v-if="item.shipper">{{ item.shipper.name }}</span>
                  <span>
                    <span v-if="item.onBehalfShipper">
                      <br />
                      On behalf of {{ item.onBehalfShipper.name }}</span
                    >
                  </span>
                </template>
                <template v-slot:[`item.forwarder.name`]="{ item }">
                  <span v-if="item.forwarder">{{ item.forwarder.name }}</span>
                  <span>
                    <span v-if="item.onBehalfForwarder">
                      <br />
                      On behalf of {{ item.onBehalfForwarder.name }}</span
                    >
                  </span>
                </template>
                <template v-slot:[`item.consignee.name`]="{ item }">
                  <span v-if="item.consignee">{{ item.consignee.name }}</span>
                  <span>
                    <span v-if="item.onBehalfConsignee">
                      <br />
                      On behalf of {{ item.onBehalfConsignee.name }}</span
                    >
                  </span>
                </template>
                <template v-slot:[`item.buyer.name`]="{ item }">
                  <span v-if="item.buyer">{{ item.buyer.name }}</span>
                  <span>
                    <span v-if="item.onBehalfBuyer">
                      <br />
                      On behalf of {{ item.onBehalfBuyer.name }}</span
                    >
                  </span>
                </template>
                <template v-slot:[`item.loadingPorts`]="{ item }">
                  <v-chip
                    color="success"
                    pill
                    outlined
                    small
                    v-if="item.allOriginPorts"
                  >
                    <v-icon small left>check</v-icon> All Ports</v-chip
                  >
                  <div v-else>
                    <span
                      v-for="(port, i) in item.consigneeProfilePorts.filter(
                        (x) => x.type == 'loading'
                      )"
                      :key="port.id"
                    >
                      <span v-if="i > 0">, </span>{{ port.name }}</span
                    >
                  </div>
                </template>
                <template v-slot:[`item.dischargePorts`]="{ item }">
                  <v-chip
                    color="success"
                    pill
                    outlined
                    small
                    v-if="item.allDestinationPorts"
                  >
                    <v-icon small left>check</v-icon> All Ports</v-chip
                  >
                  <div v-else>
                    <span
                      v-for="(port, i) in item.consigneeProfilePorts.filter(
                        (x) => x.type == 'discharge'
                      )"
                      :key="port.id"
                    >
                      <span v-if="i > 0">, </span>{{ port.name }}</span
                    >
                  </div>
                </template>
                <template v-slot:[`item.originCountry.name`]="{ item }">
                  <v-tooltip top v-if="item.originCountry">
                    <template v-slot:activator="{ on }">
                      <v-avatar size="32" v-on="on">
                        <v-img
                          contain
                          v-if="item.originCountry && item.originCountry.iso2"
                          :src="`https://cdn.loglive.io/flags/4x3/${item.originCountry.iso2.toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                    </template>
                    <span style="font-size: 12px">{{
                      item.originCountry.name
                    }}</span>
                  </v-tooltip>
                    </template>
                <template v-slot:[`item.documentationPOD`]="{ item }">
                  <template v-if="item.consigneeProfilePorts.length > 0">
                    {{ item.consigneeProfilePorts[0].name }}
                  </template>
                  <template v-else>
                    {{ "-" }}
                  </template>
                    </template>
                <template v-slot:[`item.destinationCountry.name`]="{ item }">
                  <v-tooltip top v-if="item.destinationCountry">
                    <template v-slot:activator="{ on }">
                      <v-avatar size="32" v-on="on">
                        <v-img
                          contain
                          v-if="
                            item.destinationCountry && item.destinationCountry.iso2
                          "
                          :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                    </template>
                    <span style="font-size: 12px">{{
                      item.destinationCountry.name
                    }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.incoTerm`]="{ item }">
                  <v-chip
                    small
                    outlined
                    class="mt-1"
                    v-for="term in item.consigneeProfileIncoTerms"
                    :key="term.id"
                    >{{ term.incoTerm }}</v-chip
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="saveDialog" width="600px">
          <v-card>
            <v-subheader style="height: 30px">
              Bulk Create Forecast
              <v-spacer></v-spacer>
              <v-btn icon small color="redPop" @click="saveDialog = false"><v-icon>close</v-icon></v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <div class="px-4 py-1">
              Forecast Summary
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <span style="font-size: 14px; opacity: 0.7">Breakdown</span>
                </v-col>
                <v-col cols="12" sm="1" md="1" lg="1">
                </v-col>
                <v-col cols="12" sm="1" md="1" lg="1">
                  
                  <span style="font-size: 14px; opacity: 0.7">Weeks</span>
                </v-col>
                <v-col cols="12" sm="1" md="1" lg="1">
                  <span></span>
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3">
                  <span style="font-size: 14px; opacity: 0.7">Total</span>  
                </v-col>
              </v-row>
              <v-divider class="mb-4"></v-divider>
              <v-row v-for="(container, idx) in items" :key=idx>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item>
                    <v-list-item-avatar>
                      <div style="background: white; width: 5px; height: 5px; border-radius: 50%" ></div>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ groupTotals(container).totalContainers * container.containerMultiplier }} Container{{ groupTotals(container).totalContainers > 1 || container.containerMultiplier > 1 ? 's' : '' }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{groupTotals(container).totalPallets}} Pallet{{ groupTotals(container).totalPallets > 1 ? 's' : '' }}, {{groupTotals(container).totalUnits}} Unit{{ groupTotals(container).totalUnits > 1 ? 's' : '' }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ groupProducts(container) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="1" md="1" lg="1" class="d-flex  justify-center align-center">
                  <span style="font-size: 20px; opacity: 0.2">x</span>
                </v-col>
                <v-col cols="12" sm="1" md="1" lg="1" class="d-flex justify-center align-center" style="opacity: 0.8; font-size: 14px;" >
                  {{ totalWeeks }}
                </v-col>
                <v-col cols="12" sm="1" md="1" lg="1" class="d-flex  justify-center align-center">
                  <span style="font-size: 20px;  opacity: 0.2">=</span>
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3" class="d-flex" style="opacity: 0.8; font-size: 12px;">
                  <div style="display: flex; flex-direction: column;">
                    <span> {{totalWeeks ? totalWeeks * groupTotals(container).totalContainers * container.containerMultiplier : ''}} Container{{ totalWeeks * groupTotals(container).totalContainers * container.containerMultiplier > 0 ? 's' : ''  }}</span>
                    <span> {{totalWeeks ? totalWeeks * groupTotals(container).totalPallets * container.containerMultiplier : ''}} Pallets</span>
                    <span> {{totalWeeks ? totalWeeks * groupTotals(container).totalUnits * container.containerMultiplier : ''}} Units</span>
                  </div>
                </v-col>
              </v-row>
              <v-row justify="end" class="pt-0">
                <v-col cols="12" sm="3" md="3" lg="3" style="font-size: 12px; opacity: 0.8;" class="d-flex pt-0">
                  <div style="display: flex; flex-direction: column;" >
                    <v-divider class="mb-2"></v-divider>
                    <span> {{totalContainers.total}} Containers </span>
                    <span> {{totalPallets.total}} Pallets </span>
                    <span> {{totalUnits.total}} Units </span>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div style="width: 100%; height: 30px" class="d-flex justify-center align-center">
              <v-btn small color="primary" @click="createForecast()">Create Forecast</v-btn>
            </div>
          </v-card>
        </v-dialog>

    <!-- leave this alone -->
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
    <!-- leave this alone -->
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import {AgChartsVue} from "ag-charts-vue"
import moment from "moment"
import LoadingOverlay from "../AgGrid/LoadingOverlay.vue"

/* eslint-disable vue/no-unused-components */
import ForecastActions from "./TableCells/ForecastActions.vue"
import ProductList from "./TableCells/ProductsList.vue"
import VarietyList from "./TableCells/VarietyList.vue"
import Product from "./TableCells/Product.vue"
import CellRenders from '../AgGrid/Cells/CellRenders.vue'
/* eslint-disable vue/no-unused-components */
export default {
    props: [],
    components: {
        AgGridVue,
        AgChartsVue,
        LoadingOverlay,
        ForecastActions,
        ProductList,
        VarietyList,
        Product,
        CellRenders
    },
    data: () => ({
        customers: null,
        loadingProduct: false,
        test: 0,
        saveDialog: false,
        endWeekMenu: false,
        startWeekMenu: false,
        popUpParent: null,
        shipmentProfileDialog: false,
        shiftingSeason: false,
        weekAdjustment: 0,
        gridKey: 113,
        searchProfiles: null,
        suppliers: null,
        expandWeeks: false,
        shipmentProfileHeaders: [
          {
            text: "System Ref",
            value: "systemReference",
            align: "left",
          },
          {
            text: "Status",
            value: "status",
            align: "center",
          },
        
          {
            text: "Reference",
            value: "reference",
            align: "center",
          },
          {
            text: "Consignee",
            value: "consignee.name",
            align: "center",
          },
          {
            text: "Shipper",
            value: "shipper.name",
            align: "center",
          },
          {
            text: "Forwarder",
            value: "forwarder.name",
            align: "center",
          },
          {
            text: "Origin",
            value: "originCountry.name",
            sortable: false,
            align: "center",
          },
          {
            text: "Loading Ports",
            value: "loadingPorts",
            sortable: false,
            align: "center",
          },
          {
            text: "Destination",
            value: "destinationCountry.name",
            sortable: false,
            align: "center",
          },
        
          {
            text: "Discharge Ports",
            value: "dischargePorts",
            sortable: false,
            align: "center",
          },
          {
            text: "Final Dest.",
            value: "finalDestinationCity",
            sortable: false,
            align: "center",
            documentName: null,
          },
          {
            text: "Inco Term(s)",
            value: "incoTerm",
            sortable: false,
            align: "center",
          },
        ],
        forecastSettings: {
          excludeWeeks: [],
          week: moment().toISOString(),
          plannedWeek: moment().toISOString(),
          weekType: "Pack Week",
          currencyCode: "USD",
          containers: 0,
          pallets: 0,
          cartons: 0,
        },
        items: [
          {
            palletPerContainer: 20,
            containerised: true,
            palletised: true,
            containerMultiplier: 1,
            orderCargoItems: [
              {
                tempId: '#First',
                countSize: "",
                grade: "",
                inventoryCode: "",
                mark: "",
                packCode: "",
                palletType: "Standard",
                phc: "",
                puc: "",
                unitQuantity: 0,
                palletQuantity: 0,
                packagingType: "Carton",
                varietyProductName: "",
                groupIndex: 0,
              },
            ],
          },
        ],
        forecastWeekRange: 1,
        sideNav: false,
        loadingOverlayComponent: 'LoadingOverlay',
        organisations: {
            data: [],
            total: 0,
        },
        defaultColOverview: {
            sortable: true,
            enableRowGroup: true,
            cellClass: 'd-flex align-center',
        },
        defaultColForecast: {
            sortable: true,
            enableRowGroup: false,
            cellClass: 'd-flex align-center',
            singleClickEdit: true,
            editable: true,
            suppressMovable: true,
        },
        cargoHeaders: [],
        toggleView: [0, 1],
        loadingSeasons: false,
        loadingOrganisations: false,
        availableSeasons: [],
        selectedSeason: null,
        shiftSeasonModal: false,
        deletingSeason: false,
        loadingForecast: false,
        loadingProducts: false,
        loadingSave: false,
        searchTimeout: undefined,
        confirmDelete: false,
        graphKey: 0,
        showMenu: false,
        seasonProductId: null,
        season: {},
        chosenProduct: null,
        seasonModal: false,
        x: null,
        y: null,
        searchCustomers: "",
        filters: { searchCustomers: ""},
        loadingShipmentProfiles: false,
        shipmentProfiles: {
          data: [],
        },
        existingCustomerQuery: undefined,
        existingSupplierQuery: undefined,
        savingSeason: false,
        selectedCustomer: null,
        organisationProductSetting: [],
        customerHeaders: [],
        supplierHeaders: [],
        selectedSupplier: null,
        forecastModal: false,
        forecastType: "CUSTOMER",
        gridApi: null,
        columnApi: null,
        supplierForecastModal: false,
        loadingCountries: false,
        countries: {data: []},
        customerItems: [],
        supplierItems: [],
        groupKey: 1111,
        topCustomerCountries: [],
        topCustomerVarieties: [],
        products: [],
        loadedAll: false,
        topSuppliesCountries: [],
        tradedVariations: [],
        orderSettings: [],
        topSupplierVarieties: [],
        productQuantityTypes: [
          { value: "BG", text: "Bag" },
          { value: "TT", text: "Bag, tote" },
          { value: "BA", text: "Barrel" },
          { value: "BL", text: "Bale" },
          { value: "BI", text: "Bin" },
          { value: "BX", text: "Box" },
          { value: "VL", text: "Bulk, liquid" },
          { value: "VR", text: "Bulk, solid" },
          { value: "BH", text: "Bunch" },
          { value: "BE", text: "Bundle" },
          { value: "CT", text: "Carton" },
          { value: "CS", text: "Case" },
          { value: "CN", text: "Container" },
          { value: "CR", text: "Crate" },
          { value: "DR", text: "Drum" },
          { value: "FB", text: "Flexibag" },
          { value: "JT", text: "Jutebag" },
          { value: "LU", text: "Lug" },
          { value: "PK", text: "Package" },
          { value: "PA", text: "Packet" },
          { value: "PT", text: "Pot" },
          { value: "PO", text: "Pouch" },
          { value: "RO", text: "Roll" },
          { value: "SH", text: "Sachet" },
          { value: "SA", text: "Sack" },
          { value: "SI", text: "Skid" },
          { value: "PU", text: "Tray" },
          { value: "VI", text: "Vial" },
        ],
    }),
    async mounted() {
        this.getSeasons()
        this.getRelations()
        this.getCountries()
        this.getProducts()
        this.customerHeaders = [
            {
                headerName: "Week",
                field: "plannedWeekName",
                maxWidth: 85,
            },
            {
                headerName: "Customer",
                field: "customer",
                cellRenderer: 'CellRenders',
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.customer ?? null
                    }
                }
            },
            {
                headerName: "Product",
                field: "product",
                cellClass: 'd-flex align-center justify-center',
                cellRenderer: 'CellRenders',
            },
            {
                headerName: "Containers",
                field: "containerQuantity",
                maxWidth: 100,
                width: 100,
            },
            {
                headerName: "Pallets",
                field: "palletQuantity",
                maxWidth: 75,
            },
            {
                headerName: "Units",
                field: "unitQuantity",
                maxWidth: 75,
            },
            {
                headerName: "Destination",
                field: "destinationCountry",
                cellRenderer: 'CellRenders',
            },
        ]
        this.supplierHeaders = [
            {
                headerName: "Week",
                field: "plannedWeekName",
                maxWidth: 85,
            },
            {
                headerName: "Supplier",
                field: "supplier", 
                cellRenderer: 'CellRenders',
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.supplier ?? null
                    }
                }
            },
            {
                headerName: "Product",
                field: "product",
                cellClass: 'd-flex align-center justify-center',
                cellRenderer: 'CellRenders',
            },

            {
                headerName: "Containers",
                field: "containerQuantity",
                maxWidth: 100,
                width: 100,
            },
            {
                headerName: "Pallets",
                field: "palletQuantity",
                maxWidth: 75,
            },
            {
                headerName: "Units",
                field: "unitQuantity",
                maxWidth: 75,
            },
            {
                headerName: "Destination",
                field: "destinationCountry",
                cellRenderer: 'CellRenders',
            },
        ]
    },
    watch: {
      "forecastSettings.customerId": {
        immediate: true,
        handler(val) {
          if (val) {
            this.getShipmentProfiles(val);
          } else {
            this.shipmentProfiles = { data: [] };
          }
        },
      },
      "loadedAll": {
        immediate: true,
        handler(val) {
          if(val == true) {
            this.setForecastHeaders()
            this.$nextTick(() => {
              this.gridApi.sizeColumnsToFit()
            })
          }
        }
      },
      searchCustomers: {
          immediate: true,
          handler(val) {
              if(this.searchTimeout) {
                  clearTimeout(this.searchTimeout)
              }
              this.searchTimeout = setTimeout(() => {
                  this.searchTimeout = undefined
                  this.filters.searchCustomers = val
              }, 500)
          }
      }
    },
    computed: {
      saveDisabled() {
        let result = true
        let messages = []
        let palletsDontMatchCount = 0
        let noStartDate = !this.forecastSettings.startWeek
        let noEndDate = !this.forecastSettings.endWeek
        let noCustomer = !this.forecastSettings.customerId
        let noProfile = !this.forecastSettings.consigneeProfileId
        for(let i = 0; i < this.items.length; i++) {
          let container = this.items[i]
          let total = container.orderCargoItems.reduce((sum, item) => {
            return sum + (item.unitQuantity / item.unitPerPallet);
          }, 0);
          let check = total === container.palletPerContainer;
          if(!check) {
            palletsDontMatchCount++
          }
        }
        if(palletsDontMatchCount === 0 && !noStartDate && !noEndDate && !noCustomer && !noProfile) {
          result = false
        }
        if(palletsDontMatchCount > 0) {
          messages.push(`${palletsDontMatchCount} container(s) have pallets that do not match the total pallets per container`)
        }
        if(noStartDate) {
          messages.push('Please select a start date')
        }
        if(noEndDate) {
          messages.push('Please select an end date')
        }
        if(noCustomer) {
          messages.push('Please select a customer')
        }
        if(noProfile) {
          messages.push('Please select a shipment profile')
        }
        return {disabled: result, messages}
      },
      totalPallets() {
        let total = 0;
        let totalPerWeek = 0;
        for(let i = 0; i < this.items.length; i++) {
          let container = this.items[i]
          let groupTotal = this.groupTotals(container).totalPallets
          totalPerWeek += groupTotal * container.containerMultiplier
        } 
        if(this.totalWeeks > 0) {
          total = totalPerWeek * this.totalWeeks
        }
        return {
          totalPerWeek,
          total
        }
      },
      totalContainers() {
        let totalPerWeek = 0;
        let total = 0;
        for(let i = 0; i < this.items.length; i++) {
          let container = this.items[i]
          let groupTotal = this.groupTotals(container).totalContainers
          totalPerWeek += groupTotal * container.containerMultiplier
        } 
        if(this.totalWeeks > 0) {
          total = totalPerWeek * this.totalWeeks
        }
        return {
          totalPerWeek,
          total
        }
      },
      totalUnits() {
        let total = 0;
        let totalPerWeek = 0;
        for(let i = 0; i < this.items.length; i++) {
          let container = this.items[i]
          let groupTotal = this.groupTotals(container).totalUnits
          totalPerWeek += groupTotal * container.containerMultiplier
        } 
        if(this.totalWeeks > 0) {
          total = totalPerWeek * this.totalWeeks
        }
        return {
          totalPerWeek,
          total
        }
      },
      totalWeeks() {
        let total = 0;
        if (this.forecastSettings.startWeek && this.forecastSettings.endWeek) {
          const start = moment(this.forecastSettings.startWeek, 'YYYY-MM-DD').startOf('isoWeek');
          const end = moment(this.forecastSettings.endWeek, 'YYYY-MM-DD').endOf('isoWeek');
          total = end.diff(start, 'weeks') + 1;
        } else {
          return 0;
        }
        if (this.forecastSettings.excludeWeeks.length > 0) {
          total -= this.forecastSettings.excludeWeeks.length;
        }
        return total;
      },
      secondRowHeight() {
        let firstRow = document.querySelector('#firstRow')
        if(!firstRow) {
          return `calc(100vh - 190px - 90px)`
        }
        return `calc(100vh - ${firstRow.clientHeight}px - 115px)`
      },

      filteredProfiles() {
        let result = this.shipmentProfiles.data.filter(
          (x) => x.status == "Approved"
        );
        if (this.search) {
          result = result.filter(
            (x) =>
              (x.systemReference && x.systemReference .toLowerCase() .includes(this.searchProfiles.toLowerCase())) ||
              (x.reference && x.reference .toLowerCase() .includes(this.searchProfiles.toLowerCase())) ||
              (x.buyer && x.buyer.name .toLowerCase() .includes(this.searchProfiles.toLowerCase())) ||
              (x.forwarder && x.forwarder.name .toLowerCase() .includes(this.searchProfiles.toLowerCase())) ||
              (x.shipper && x.shipper.name .toLowerCase() .includes(this.searchProfiles.toLowerCase())) ||
              (x.consignee && x.consignee.name .toLowerCase() .includes(this.searchProfiles.toLowerCase())) ||
              (x.onBehalfForwarder && x.onBehalfForwarder.name .toLowerCase() .includes(this.searchProfiles.toLowerCase())) ||
              (x.onBehalfShipper && x.onBehalfShipper.name .toLowerCase() .includes(this.searchProfiles.toLowerCase())) ||
              (x.onBehalfConsignee && x.onBehalfConsignee.name .toLowerCase() .includes(this.searchProfiles.toLowerCase())) ||
              (x.consigneeProfileIncoTerms && x.consigneeProfileIncoTerms .map((y) => y.incoTerm.toLowerCase()) .includes(this.searchProfiles.toLowerCase()))
          );
        }
        return result;
      },
        filteredCustomers() {
            if(this.customers.length === 0) {
                return []
            }
            let customers = this.customers
            if(this.filters.searchCustomers) {
                customers = customers.filter(customer => {
                    return customer.organisation.name.toLowerCase().includes(this.filters.searchCustomers.toLowerCase())
                })
            }
            return customers
        },
        
        filterProducts() {
          if(!this.products.data || this.products.data.length === 0) {
            return []
          }
          let result = [];
          if (this.seasonProductId) {
            result = this.products.data.filter((x) => (x.id == this.seasonProductId || x.parentProductId == this.seasonProductId) && x.type == "Product" );
          } else {
            result = this.products.data.filter((x) => x.type == "Product");
          }
          return result;
        },
        getWeeksBetween() {
          let weeksArray = [];
          if(this.forecastSettings.startWeek && this.forecastSettings.endWeek) {
            const start = moment(this.forecastSettings.startWeek, 'YYYY-MM-DD').startOf('isoWeek');
            const end = moment(this.forecastSettings.endWeek, 'YYYY-MM-DD').endOf('isoWeek');      
            let startWeek = start.isoWeek();
            let startYear = start.isoWeekYear();
            const endWeek = end.isoWeek();
            const endYear = end.isoWeekYear();
            while (startYear < endYear || (startYear === endYear && startWeek <= endWeek)) {
              weeksArray.push(`W${startWeek}`);
              startWeek++;
              if (startWeek > moment().isoWeeksInYear()) {
                startWeek = 1;
                startYear++;
              }
            }
          }
          return weeksArray;
        }
    },
    methods: {
      async createForecast() {
        this.loadingSave = true
        let items = this.items.map((container) => {
          let {gridApi, ...other} = container
          return {...other}
        })
        let weeks = this.getWeeksFinal()

        let forecasts = []
        for(let i = 0; i < items.length; i++) {
          let item = items[i]
          let obj = {
            containerised: item.containerised,
            palletised: item.palletised,
            palletPerContainer: item.palletPerContainer,
            weekType: this.forecastSettings.weekType,
            seasonId: this.forecastSettings.seasonId,
            consigneeProfileId: this.forecastSettings.consigneeProfileId,
            destinationCountryId: this.forecastSettings.destinationCountryId,
            forwarderId: this.forecastSettings.consigneeProfile.forwarderId,
            transportCoordinatorId: this.forecastSettings.consigneeProfile.transportCoordinatorId,
            organisationId: this.$store.state.currentOrg.id,
            forecastType: this.forecastType,
            palletQuantity: this.groupTotals(item).totalPallets > 0 ? this.groupTotals(item).totalPallets * item.containerMultiplier : 0,
            containerQuantity: this.groupTotals(item).totalContainers > 0 ? this.groupTotals(item).totalContainers * item.containerMultiplier : 0,
            unitQuantity: this.groupTotals(item).totalUnits > 0 ? this.groupTotals(item).totalUnits * item.containerMultiplier : 0,
            orderCargoItems: item.orderCargoItems.map((x) => {
              let {gridApi, ...other} = x
              return {...other}
            })
          }
          if(this.forecastType == 'CUSTOMER') {
            obj.customerId = this.forecastSettings.customerId
          } else {
            obj.supplierId = this.forecastSettings.supplierId
          }
          forecasts.push(obj)
        }
        let weeklyForecasts = []
        for(let i = 0; i < weeks.length; i++) {
          let week = weeks[i]
          let plannedWeek = week.split(' - W')[1]
          plannedWeek ? plannedWeek = parseInt(plannedWeek) : null
          let plannedYear = week.split(' - W')[0]
          plannedYear ? plannedYear = parseInt(plannedYear) : null

          for(let j = 0; j < forecasts.length; j++) {
            let forecast = forecasts[j]
            for(let f = 0; f < forecast.orderCargoItems.length; f++) {
              let fItem = forecast.orderCargoItems[f]
              if(!fItem.varietyProductId && fItem.varietyProductName && fItem.productId) {
                let varieties = this.products?.data.filter( (x) => x.parentProductId == fItem.productId && x.type == "Variety" );
                let find = varieties.find(x => x.name == fItem.varietyProductName)
                if(find) {
                  fItem.varietyProductId = find.id
                }
              }
              if(!fItem.packagingTypeCode && fItem.packagingType) {
                let find = this.productQuantityTypes.find(x => x.text == fItem.packagingType)
                if(find) {
                  fItem.packagingTypeCode = find.value
                }
              }
              if(!fItem.palletTypeCode && fItem.palletType) {
                let code = fItem.palletType == 'Standard' ? 'S' : fItem.palletType == 'Chep' ? 'C' : fItem.palletType == 'High Cube' ? 'H' : fItem.palletType == 'Euro' ? 'E' : null
                if(code) {
                  fItem.palletTypeCode = code
                }
              }
            }
            let obj = {
              ...forecast,
              plannedWeekName: week,
              plannedWeek: plannedWeek,
              plannedYear: plannedYear,
            }
            weeklyForecasts.push(obj)
          }
        }
        let result = await this.$API.createForecast(weeklyForecasts)
        if(result) {
          this.loadingSave = false
          this.saveDialog = false
          this.$message.success(`Successfully created forecast!`)
          this.forecastSettings = {
            excludeWeeks: [],
            week: moment().toISOString(),
            plannedWeek: moment().toISOString(),
            weekType: "Pack Week",
            currencyCode: "USD",
            containers: 0,
            pallets: 0,
            cartons: 0,
          }
          this.items = [
            {
              palletPerContainer: 20,
              containerised: true,
              palletised: true,
              containerMultiplier: 1,
              orderCargoItems: [
                {
                  tempId: '#First',
                  countSize: "",
                  grade: "",
                  inventoryCode: "",
                  mark: "",
                  packCode: "",
                  palletType: "Standard",
                  phc: "",
                  puc: "",
                  unitQuantity: 0,
                  palletQuantity: 0,
                  packagingType: "Carton",
                  varietyProductName: "",
                  groupIndex: 0,
                },
              ],
            },
          ]
          this.forecastModal = false
          this.getForecast(this.selectedSeason)
        }
      },
      onCellValueChanged(event, groupIndex) {
        if(event?.colDef?.field == 'product' && event.data.productId) {
          this.getOrganisationProductSetting({productId: event.data.productId, tempId: event.data.tempId ?? event.data.id, groupIndex: groupIndex})
        } else{
          let item = this.items[groupIndex].orderCargoItems.find(x => x.tempId == event.data.tempId)
          if(!item) {
            item = this.items[groupIndex].orderCargoItems.find(x => x.id == event.data.id)
          }
          let palletCount = this.items[groupIndex].palletPerContainer 
          if(this.items[groupIndex].containerised && this.items[groupIndex].palletised) {
            if (event?.colDef?.field == 'percentage') {
              if(item.unitPerPallet && palletCount) {
                let totalPallets = Math.ceil(item.unitPerPallet * palletCount)
                if(totalPallets) {
                  item.unitQuantity = Math.ceil((totalPallets * event.newValue) / 100)
                  item.palletQuantity = (palletCount * event.newValue) / 100
                }
              }
            } else if (event?.colDef?.field == 'unitQuantity') {
              if(item.unitPerPallet && palletCount) {
                let totalPallets = Math.ceil(item.unitPerPallet * palletCount)
                if(totalPallets) {
                  item.percentage = (event.newValue / totalPallets) * 100
                  item.palletQuantity = (palletCount * item.percentage) / 100
                }
              }
            } else if (event?.colDef?.field == 'palletQuantity') {
              if(item.unitPerPallet && palletCount) {
                let totalPallets = Math.ceil(item.unitPerPallet * palletCount)
                if(totalPallets) {
                  item.percentage = (event.newValue / palletCount) * 100
                  item.unitQuantity = Math.ceil((totalPallets * item.percentage) / 100)
                }
              }
            } else if (event?.colDef?.field == 'unitPerPallet') {
              if(item.unitPerPallet && palletCount) {
                let totalPallets = Math.ceil(event.newValue * palletCount)
                if(totalPallets) {
                  if(item.percentage) {
                    item.unitQuantity = Math.ceil((totalPallets * item.percentage) / 100)
                    item.palletQuantity = (palletCount * item.percentage) / 100
                  } else if(item.palletQuantity) {
                    item.unitQuantity = Math.ceil((totalPallets * item.palletQuantity) / palletCount)
                  }
                }
              }
            }
          }
        }
        this.items[groupIndex].gridApi.setGridOption("rowData", this.items[groupIndex].orderCargoItems)
      },
      async getShipmentProfiles() {
        this.loadingShipmentProfiles = true;
        this.shipmentProfiles = await this.$API.listCustomerConsigneeProfile(
          this.forecastSettings.customerId,
          {}
        );
        this.loadingShipmentProfiles = false;
      },
      setShipmentProfile(profile) {
        let {consignee, shipper, forwarder, onBehalfShipper, onBehalfConsignee, onBehalfForwarder, ...other} = profile
        let organisations = [...this.customers, ...this.suppliers]
        consignee ? consignee.alias = organisations.find(x => x.id == consignee.id)?.alias ?? null : null
        consignee ? consignee.icon = organisations.find(x => x.id == consignee.id)?.icon ?? organisations.find(x => x.id == consignee.id)?.logo : null
        shipper ? shipper.alias = organisations.find(x => x.id == shipper.id)?.alias ?? null : null
        shipper ? shipper.icon = organisations.find(x => x.id == shipper.id)?.icon ?? organisations.find(x => x.id == shipper.id)?.logo : null
        forwarder ? forwarder.alias = organisations.find(x => x.id == forwarder.id)?.alias ?? null : null
        forwarder ? forwarder.icon = organisations.find(x => x.id == forwarder.id)?.icon ?? organisations.find(x => x.id == forwarder.id)?.logo : null
        onBehalfShipper ? onBehalfShipper.alias = organisations.find(x => x.id == onBehalfShipper.id)?.alias ?? null : null
        onBehalfConsignee ? onBehalfConsignee.alias = organisations.find(x => x.id == onBehalfConsignee.id)?.alias ?? null : null
        onBehalfForwarder ? onBehalfForwarder.alias = organisations.find(x => x.id == onBehalfForwarder.id)?.alias ?? null : null
        this.forecastSettings.consigneeProfile = profile;
        this.forecastSettings.consigneeProfileId = profile.id;
        this.forecastSettings.destinationCountryId = profile.destinationCountryId;
        this.shipmentProfileDialog = false;
      },
      groupTotals(container){
        let totalPallets = 0;
        let totalContainers = 0;
        let totalUnits = 0
        container.orderCargoItems.forEach(x => {
          x.unitQuantity > 0 && x.unitPerPallet > 0 ? totalPallets += Math.ceil(x.unitQuantity / x.unitPerPallet) : 0
          totalUnits += x.unitQuantity
        })
        totalContainers = container.palletPerContainer ? Math.ceil(totalPallets / container.palletPerContainer) : 0
        return {
          totalPallets,
          totalContainers,
          totalUnits
        }
      },
      groupProducts(container) {
        let productsArray = []
        container.orderCargoItems.forEach(x => {
          x.productName ? productsArray.push(x.productName) : null
        })
        let productsString = '';
        if(productsArray.length > 0) {
          productsArray = [...new Set(productsArray)]
          productsString = productsArray[0]
          if(productsArray.length > 1) {
            for(let i = 1; i < productsArray.length; i++) {
              productsString = productsString + `, ${productsArray[i]}`
            }
          }
        }
        return productsString
      },
      getWeeksFinal() {
        let weeksArray = [];
        if(this.forecastSettings.startWeek && this.forecastSettings.endWeek) {
          const start = moment(this.forecastSettings.startWeek, 'YYYY-MM-DD').startOf('isoWeek');
          const end = moment(this.forecastSettings.endWeek, 'YYYY-MM-DD').endOf('isoWeek');      
          let startWeek = start.isoWeek();
          let startYear = start.isoWeekYear();
          const endWeek = end.isoWeek();
          const endYear = end.isoWeekYear();
          while (startYear < endYear || (startYear === endYear && startWeek <= endWeek)) {
            if(!this.forecastSettings.excludeWeeks.includes(`W${startWeek}`)) {
              weeksArray.push(`${startYear} - W${startWeek}`);
            }
            startWeek++;
            if (startWeek > moment().isoWeeksInYear()) {
              startWeek = 1;
              startYear++;
            }
          }
        }
        return weeksArray;
      },

      validGroup(container){
        let total = container.orderCargoItems.reduce((sum, item) => {
          return sum + (item.unitQuantity / item.unitPerPallet);
        }, 0);
        let result = total === container.palletPerContainer;
        return result;
      },
      addLineItem(itemIndex, addGroup) {
        let orderCargoItem = {
            tempId: new Date().getTime().toString() + crypto.getRandomValues(new Uint32Array(1))[0],
            countSize: "",
            grade: "",
            inventoryCode: "",
            mark: "",
            packCode: "",
            palletType: "Standard",
            phc: "",
            puc: "",
            unitQuantity: 0,
            packagingType: 'Carton',
            varietyProductName: null,
            varietyId: null,
          }
        if(!addGroup) {
          orderCargoItem.groupIndex = itemIndex
          this.items[itemIndex].orderCargoItems = [...this.items[itemIndex].orderCargoItems, orderCargoItem]
        } else {
          let groupIndex = this.items.length
          orderCargoItem.groupIndex = groupIndex
          this.items.push({
            palletPerContainer: 20,
            containerMultiplier: 1,
            containerised: true,
            palletised: true,
            orderCargoItems: [orderCargoItem]
          });
        }
      },
      filterVarieties(productId) {
          let result = [];
          if (productId && this.products?.data?.length > 0) {
            result = this.products?.data.filter( (x) => x.parentProductId == productId && x.type == "Variety" );
          }
          return result;
        },
      setForecastHeaders() {
        this.cargoHeaders = [
          {
            headerName: "",
            field: "actions",
            editable: false,
            cellRenderer: 'ForecastActions',
            maxWidth: 60,
            minWidth: 60,
            width: 60,
            resizable: false,
            pinned: 'left',
            cellRendererParams: (params) => {
              return {
                duplicateRow: () => this.duplicateItem(params.data),
                deleteRow: () => this.deleteItem(params.data),
              }
            }
          },
          {
            headerName: "Quantity",
            children: [
              {
                headerName: `Units`,
                suppressHeaderMenuButton: true,
                field: "unitQuantity",
                cellDataType: 'number',
                maxWidth: 60,
                minWidth: 60,
                cellEditor: "agNumberCellEditor",
                aggFunc: 'sum',
                cellEditorParams: {
                  min: 0,
                },
              },
              {
                headerName: `Pallets`,
                field: "palletQuantity",
                cellDataType: 'number',
                suppressHeaderMenuButton: true,
                maxWidth: 60,
                minWidth: 60,
                cellEditor: "agNumberCellEditor",
                aggFunc: 'sum',
                cellEditorParams: {
                  min: 0,
                },
              },
              {
                headerName: "%",
                field: "percentage",
                suppressHeaderMenuButton: true,
                minWidth: 60,
                maxWidth: 60,
                cellEditor: "agNumberCellEditor",
                aggFunc: 'sum',
                cellEditorParams: {
                  min: 0,
                  max: 100,
                },
              }
            ]
          },
          {
            headerName: "Details",
            children: [
              {
                headerName: 'Product',
                field: "product",
                valueGetter: (params) => {
                  return params.data.productName
                },
                valueSetter: (params) => {
                  params.data.productId = params.newValue.id
                  params.data.productName = params.newValue.name
                  params.data.productCode = params.newValue.code
                },
                cellEditor: "agRichSelectCellEditor",
                cellEditorParams: {
                  cellRenderer: "ProductList",
                  values: this.filterProducts
                },
              },
              {
                headerName: "Var",
                field: "varietyProductName",
                valueGetter: (params) => {
                  return params.data.varietyProductName
                },
                valueSetter: (params) => {
                  params.data.varietyProductName = params.newValue.name
                  params.data.varietyProductId = params.newValue.id
                },
                minWidth: 180,
                cellEditor: "agRichSelectCellEditor",
                cellEditorParams: (params) => {
                  return {
                    cellRenderer: "VarietyList",
                    values: this.filterVarieties(params.data.productId ?? null)
                  }
                },
              },
              {
                headerName: "Brand/Mark",
                field: "mark",
                cellEditor: "agRichSelectCellEditor",
                cellEditorParams: (p) => { 
                  return p.data ? {values: this.filteredValues('mark', p.data)} : {values: this.filteredValues('mark')}
                },
              },
              {
                headerName: "Pack",
                field: "packCode",
                maxWidth: 90,
                minWidth: 90,
                cellEditor: "agRichSelectCellEditor",
                cellEditorParams: (p) => { 
                  return p.data ? {values: this.filteredValues('packCode', p.data)} : {values: this.filteredValues('packCode')}
                },
              },
              { 
                headerName: "Cnt/Size",  
                field: "countSize",
                maxWidth: 90,
                minWidth: 90,
                cellEditor: "agRichSelectCellEditor",
                cellEditorParams: (p) => { 
                  return p.data ? {values: this.filteredValues('countSize', p.data)} : {values: this.filteredValues('countSize')}
                },
              },
              {
                headerName: "Class",
                field: "grade",
                cellEditor: "agRichSelectCellEditor",
                cellEditorParams: (p) => { 
                  return p.data ? {values: this.filteredValues('grade', p.data)} : {values: this.filteredValues('grade')}
                },
              },
              {
                headerName: "Inv Code",
                field: "inventoryCode",
                cellEditor: "agRichSelectCellEditor",
                cellEditorParams: (p) => { 
                  return p.data ? {values: this.filteredValues('inventoryCode', p.data)} : {values: this.filteredValues('inventoryCode')}
                },
              },
              { 
                headerName: "PHC",  
                field: "phc",
                cellEditor: "agTextCellEditor",
              },
              { 
                headerName: "PUC",  
                field: "puc",
                cellEditor: "agTextCellEditor",
              },
            ]
          },
          {
            headerName: "Settings",
            children: [
              {
                headerName: `Type`,
                field: "packagingType",
                valueSetter: (params) => {
                  params.data.packagingType = params.newValue
                  let find = this.productQuantityTypes.find(x => x.text == params.newValue)
                  if(find) {
                    params.data.packagingTypeCode = find.value
                  }
                },
                maxWidth: 85,
                minWidth: 85,
                cellEditor: "agRichSelectCellEditor",
                cellEditorParams: {values: this.productQuantityTypes.map(x => x.text)},
              },
              {
                headerName: "Pallet Type",
                field: "palletType",
                valueSetter: (params) => {
                  params.data.palletType = params.newValue
                  let code = params.newValue == 'Standard' ? 'S' : params.newValue == 'Chep' ? 'C' : params.newValue == 'High Cube' ? 'H' : params.newValue == 'Euro' ? 'E' : null
                  if(code) {
                    params.data.palletTypeCode = code
                  }
                },
                cellEditor: "agRichSelectCellEditor",
                cellEditorParams: (p) => { 
                  return p.data ? {values: this.filteredValues('palletType', p.data)} : {values: this.filteredValues('palletType')}
                },
              },
              {
                headerName: "Units per pallet",
                field: "unitPerPallet",
                cellDataType: 'number',
                cellEditor: "agNumberCellEditor",
                cellEditorParams: {
                  min: 0,
                },
              }
            ]
          }
        ]
        this.$nextTick(() => {
          this.gridApi.sizeColumnsToFit()
        })
      },
      loadData(key) {
        return this.productQuantityTypes.map(x => x.text)
      },
      onGridReady(params, itemsIndex) {
        this.items[itemsIndex].gridApi = params.api;
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.popUpParent = document.querySelector("body");
      },
      headersUpdate(container, idx) {
        let headers = JSON.parse(JSON.stringify(this.cargoHeaders))
        if(!container.palletised) {
          headers[1].children[1].hide = true
          headers[3].children[2].hide = true
          this.items[idx].gridApi.setGridOption("columnDefs", headers);
          this.items[idx].gridApi.sizeColumnsToFit()
        } else {
          headers[1].children[1].hide = false
          headers[3].children[2].hide = false
          this.items[idx].gridApi.setGridOption("columnDefs", headers);
          this.items[idx].gridApi.sizeColumnsToFit()
        }
      },
      async getOrganisationProductSetting({productId, tempId, groupIndex}) {
        let orgProductSettings;
        let variations;
        let orderSettings;
        if(this.organisationProductSetting.length > 0 && this.organisationProductSetting.some(x => x.productId == productId)) {
          orgProductSettings = this.organisationProductSetting.find(x => x.productId == productId)
        } else {
          let settings = await this.$API.getOrganisationTradedProduct(productId);
          this.organisationProductSetting = [settings, ...this.organisationProductSetting]
          orgProductSettings = settings
        }
        if(this.tradedVariations.length > 0 && this.tradedVariations.some(x => x.productId == productId)) {
          variations = this.tradedVariations.filter(x => x.productId == productId)
        } else {
          let result = await this.$API.getTradedVariationByProduct( productId );
          if(result) {
            result.forEach(x => x.productId = productId)
            variations = result
            this.tradedVariations = [...this.tradedVariations, ...variations]
          }
        }
        if(this.orderSettings.length > 0 && this.orderSettings.some(x => x.productId == productId)) {
          orderSettings = this.orderSettings.filter(x => x.productId == productId)
        } else {
          let settings = await this.$API.getOrderSettings(productId);
          this.orderSettings = [...settings, ...this.orderSettings]
          orderSettings = settings
        }
        if (!this.items[groupIndex].id && orgProductSettings) {
          let unitType = this.productQuantityTypes.find(x => x.value == orgProductSettings.baseUnit) ?? null
          let item = this.items[groupIndex].orderCargoItems.find(x => x.tempId == tempId || x.id == tempId)
          if(item) {
            item.unitPerPallet = orgProductSettings.unitPerPallet;
            item.packagingType = unitType ? unitType.text : orgProductSettings.baseUnit;
          }
        }
        this.loadedAll = true;
      },

      filteredValues(type, item = {}) {
        if (type == "packCode") {
          if(this.tradedVariations.length == 0 || !item || !item.productId) {
            return []
          }
          let productVariations = this.tradedVariations.filter((x) => x.productId == item.productId);
          if(productVariations.length == 0) {
            return []
          }
          let codes = productVariations.filter((x) => x.packCode).map(x => x.packCode)
          codes = [...new Set(codes)]
          return codes
          } else if (type == "countSize") {
          if(this.tradedVariations.length == 0 || !item || !item.productId) {
            return []
          }
          let productVariations = this.tradedVariations.filter((x) => x.productId == item.productId);
          if(productVariations.length == 0) {
            return []
          }
          let filtered = productVariations.filter((x) => x.countSize);
          if (item.packCode) {
            filtered = filtered.filter((x) => x.packCode == item.packCode);
          }
          return filtered.map((x) => x.countSize);
        } else if (type == "palletType") {
          return ["Standard", "Chep", "High Cube", "Euro",
            // { name: "Standard", value: "S" },
            // { name: "Chep", value: "C" },
            // { name: "High Cube", value: "H" },
            // { name: "Euro", value: "E" },
          ];
        } else {
          let productOrderSettings;
          if(item.productId) {
            productOrderSettings = this.orderSettings.filter((x) => x.productId == item.productId);
          } else {
            productOrderSettings = this.orderSettings
          }
          let result = productOrderSettings.filter((x) => x.key == type);
          result = result.map((x) => x.value);
          return result
        }
      },
      async deleteItem(item) {
          if (item.tempId) {
            if(this.items[item.groupIndex].orderCargoItems.length == 1) {
              this.items.splice(item.groupIndex, 1)
            } else {
              this.items[item.groupIndex].orderCargoItems = this.items[item.groupIndex].orderCargoItems.filter(x => x.tempId != item.tempId);
            }
          } else {
            this.items[item.groupIndex].orderCargoItems = this.items[item.groupIndex].orderCargoItems.filter(x => x.id != item.id);
            await this.$API.updateForecastLineItem({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
      },
      duplicateItem(item) {
        let newItem = { ...item };
        newItem.tempId = new Date().getTime().toString() + crypto.getRandomValues(new Uint32Array(1))[0];
        delete newItem.id;
        delete newItem.createdAt;
        delete newItem.updatedAt;
        this.items[item.groupIndex].orderCargoItems = [...this.items[item.groupIndex].orderCargoItems, newItem]
      },
      postProcessPopup(params) {
        if(params.type !== 'columnMenu') {
          return;
        }
        const popup = params.ePopup;
        let top = popup.style.top;
        top = top.replace("px", "");
        const oldTop = parseInt(top);
        popup.style.top = `${oldTop + 25}px`;
      },
      includeOrExclude(week) {
        if(this.forecastSettings.excludeWeeks.includes(week)) {
          this.forecastSettings.excludeWeeks = this.forecastSettings.excludeWeeks.filter(x => x !== week)
        } else {
          this.forecastSettings.excludeWeeks.push(week)
        }
      },
      async getCountries() {
        this.loadingCountries = true;
        this.countries = await this.$API.searchCountries({});
        this.loadingCountries = false;
      },
      popOpenWeeks(type) {
        if(type == 'start') {
          if(this.forecastSettings.endWeek) {
            this.expandWeeks = true
          }
        } else {
          if(this.forecastSettings.startWeek) {
            this.expandWeeks = true
          }
        }
      },
      async shiftSeason(){
        this.shiftingSeason = true
        let result = await this.$API.shiftSeason({
          seasonId: this.season.id,
          weekAdjustment: this.weekAdjustment
        })
        this.shiftingSeason = false
        this.shiftSeasonModal = false
        this.getSeasons()
        this.getForecast()
      },
      forecastModalChange(type) {
        this.forecastType = type
        this.forecastModal = true
      },
      async deleteSeason() {
        this.deletingSeason = true;
        await this.$API.updateSeason({
          id: this.season.id,
          isActive: false,
          isDeleted: true,
        });
        this.availableSeasons = this.availableSeasons.filter(
          (x) => x.id != this.season.id
        );
        this.deletingSeason = false;
        this.confirmDelete = false;
        this.season = {};
      },
      seasonContext(e) {
          this.season = this.selectedSeason
          e.preventDefault();
      },
      seasonClick() {
          this.season = this.selectedSeason
          this.forecastSettings.season = this.selectedSeason
          this.forecastSettings.seasonId = this.selectedSeason.id
          this.seasonProductId = this.selectedSeason.productId
      },
      addSeason() {
        this.season = {
          year: new Date().getFullYear(),
          name: "",
          start: moment().startOf("year").format("YYYY-MM-DD"),
          end: moment().endOf("year").format("YYYY-MM-DD"),
        };
        this.seasonModal = true;
      },
      onFirstDataRendered(params) {
          params.api.sizeColumnsToFit();
      },
      formatBaseUnit(type) {
        let result = this.productQuantityTypes.find((x) => x.value == type);
        return result ? result.text : "";
      },
      async saveSeason() {
        this.savingSeason = true;
        this.season.startWeek = moment(this.season.start).week();
        this.season.endWeek = moment(this.season.end).week();
        this.season.startYear = moment(this.season.start).year();
        this.season.endYear = moment(this.season.end).year();
        let result = {};
        if (!this.season.id) {
          result = await this.$API.createSeason(this.season);
        } else {
          result = await this.$API.updateSeason(this.season);
        }
        if (this.season.productId) {
          let findProduct = this.products.data.find(
            (x) => x.id == this.season.productId
          );
          if (findProduct) {
            result.product = findProduct;
          }
        }
        let index = this.availableSeasons.findIndex((x) => x.id == result.id);
        if (index > -1) {
          this.availableSeasons.splice(index, 1, result);
        } else {
          this.availableSeasons.push(result);
        }
        this.savingSeason = false;
        this.seasonModal = false;
        this.season = {};
        this.selectedSeason = result;
      },
      async getSeasons() {
        this.loadingSeasons = true;
        this.availableSeasons = await this.$API.getSeasons();
        if (this.availableSeasons.length > 0 && !this.selectedSeason) {
          this.selectedSeason = this.availableSeasons[0];
          this.forecastSettings.season = this.selectedSeason
          this.forecastSettings.seasonId = this.selectedSeason.id
          this.seasonProductId = this.selectedSeason.productId
          this.getForecast(this.selectedSeason)
        }
        this.loadingSeasons = false;
      },
      async getProducts() {
        this.loadingProducts = true;
        this.products = await this.$API.getProducts({});
        this.setForecastHeaders()
        this.loadingProducts = false;
      },
      async getRelations() {
          this.loadingOrganisations = true;
          if ( localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id] ) {
              this.organisations = JSON.parse( localStorage[ "relatedOrganisations_" + this.$store.state.currentOrg.id ]);
          }
          this.organisations = await this.$API.getRelationBasic({});
          let customers = this.organisations.data.filter(x => x.isCustomer)
          for(let i = 0; i < customers.length; i++) {
              let {alias, relatedOrganisation, relationshipTeams, ...otherInfo} = customers[i]
              customers[i] = {
                organisationRelationship: {...otherInfo, alias: alias},
                relatedTeams: relationshipTeams,
                ...relatedOrganisation,
                alias: alias ?? relatedOrganisation.alias ?? null
              }
          }
          this.customers = customers
          if(this.customerItems?.length > 0) {
            for(let i = 0; i < this.customerItems.length; i++) {
              let item = this.customerItems[i]
              if(this.customers.length > 0) {
                item.customer?.id ? item.customer.alias = this.customers.find(x => x.id == item.customer.id)?.alias ?? item.customer.alias : null
              }
            }
          }
          let suppliers = this.organisations.data.filter(x => x.isSupplier)
          for(let i = 0; i < suppliers.length; i++) {
              let {alias, relatedOrganisation, relationshipTeams, ...otherInfo} = suppliers[i]
              suppliers[i] = {
                  organisationRelationship: {...otherInfo, alias: alias},
                  relatedTeams: relationshipTeams,
                  ...relatedOrganisation,
                  alias: alias ?? relatedOrganisation.alias ?? null
              }
          }
          this.suppliers = suppliers
          if(this.supplierItems?.length > 0) {
            for(let i = 0; i < this.supplierItems.length; i++) {
              let item = this.supplierItems[i]
              if(this.suppliers.length > 0) {
                item.supplier?.id ? item.supplier.alias = this.suppliers.find(x => x.id == item.supplier.id)?.alias ?? item.supplier.alias : null
              }
            }
          }
          localStorage.setItem( "relatedOrganisations_" + this.$store.state.currentOrg.id, JSON.stringify(this.organisations) );
          this.loadingOrganisations = false;
      },
      async getForecast(season = null) {
          if (season) {
            this.selectedSeason = season;
          }
          if (this.selectedSeason) {
              if (this.loadingForecast && this.existingCustomerQuery) {
                  this.existingCustomerQuery.abort()
              }
              if(this.loadingForecast && this.existingSupplierQuery) {
                  this.existingSupplierQuery.abort()
              }
              this.loadingForecast = true;
              this.existingCustomerQuery = new AbortController();
              this.existingSupplierQuery = new AbortController();
              const customerSignal = this.existingCustomerQuery.signal
              const supplierSignal = this.existingSupplierQuery.signal
              let customerParams = {
                  seasonId: this.selectedSeason.id,
                  forecastType: 'CUSTOMER',
                }
              let supplierParams = {
                  seasonId: this.selectedSeason.id,
                  forecastType: 'SUPPLIER',
              }
              let [customerResult, supplierResult] = await Promise.allSettled([
                  this.$API.getShipmentsForecasts({ params: customerParams, signal:customerSignal}),
                  this.$API.getShipmentsForecasts({ params: supplierParams, signal:supplierSignal})
              ])
              if (customerResult.status == "rejected") {
                  console.error(customerResult.reason);
                  this.loadingForecast = false;
                  this.$message.error("Failed to load customer forecast");
                  return;
              }
              if (supplierResult.status == "rejected") {
                  console.error(supplierResult.reason);
                  this.loadingForecast = false;
                  this.$message.error("Failed to load supplier forecast");
                  return;
              }
              this.customerItems = customerResult.value
              this.supplierItems = supplierResult.value
              this.graphKey++;
              this.loadingForecast = false;
              if(this.customerItems?.length > 0) {
                  for(let i = 0; i < this.customerItems.length; i++) {
                      let item = this.customerItems[i]
                      if(this.customers.length > 0) {
                        item.customer?.id ? item.customer.alias = this.customers.find(x => x.id == item.customer.id)?.alias ?? item.customer.alias : null
                      }
                  }
                  let uniqueCountriesCustomer = [...new Set( this.customerItems .filter((x) => x.destinationCountryId) .map((x) => x.destinationCountryId) )];
                  let customerCountries = [];
                  let totalCustomerUnits = this.customerItems.filter(x=>x.id && x.unitQuantity).reduce( (a, b) => a + b.unitQuantity, 0);
                  for (let i = 0; i < uniqueCountriesCustomer.length; i++) {
                      let country = uniqueCountriesCustomer[i];
                      let countryData = this.customerItems.filter( (x) => x.destinationCountryId == country );
                      let countryName = countryData[0].destinationCountry.name;
                      let containers = countryData.reduce( (a, b) => a + b.containerQuantity, 0 );
                      let pallets = countryData.reduce((a, b) => a + b.palletQuantity, 0);
                      let units = countryData.reduce((a, b) => a + b.unitQuantity, 0);
                      customerCountries.push({
                        iso2: countryData[0].destinationCountry.iso2,
                        name: countryName,
                        containers: containers,
                        pallets: pallets,
                        units: units,
                        percentage: Math.round((units / totalCustomerUnits) * 100),
                      });
                  }
                  this.topCustomerCountries = customerCountries.sort((a, b) => (a.units > b.units ? -1 : b.units > a.units ? 1 : 0)).slice(0, 5);
              }
              if(this.supplierItems?.length > 0) {
                for(let i = 0; i < this.supplierItems.length; i++) {
                      let item = this.supplierItems[i]
                      if(this.suppliers.length > 0) {
                        item.supplier?.id ? item.supplier.alias = this.suppliers.find(x => x.id == item.supplier.id)?.alias ?? item.supplier.alias : null
                      }
                  }
                  let uniqueCountriesSupplier = [...new Set( this.supplierItems .filter((x) => x.destinationCountryId) .map((x) => x.destinationCountryId) )];
                  let supplierCountries = [];
                  let totalSupplierUnits = this.supplierItems.filter(x=>x.id && x.unitQuantity).reduce( (a, b) => a + b.unitQuantity, 0);
                  for (let i = 0; i < uniqueCountriesSupplier.length; i++) {
                      let country = uniqueCountriesSupplier[i];
                      let countryData = this.supplierItems.filter( (x) => x.destinationCountryId == country );
                      let countryName = countryData[0].destinationCountry.name;
                      let containers = countryData.reduce( (a, b) => a + b.containerQuantity, 0 );
                      let pallets = countryData.reduce((a, b) => a + b.palletQuantity, 0);
                      let units = countryData.reduce((a, b) => a + b.unitQuantity, 0);
                      supplierCountries.push({
                        iso2: countryData[0].destinationCountry.iso2,
                        name: countryName,
                        containers: containers,
                        pallets: pallets,
                        units: units,
                        percentage: Math.round((units / totalSupplierUnits) * 100),
                      });
                  }
                  this.topSuppliesCountries = supplierCountries.sort((a, b) => (a.units > b.units ? -1 : b.units > a.units ? 1 : 0)).slice(0, 5);
              }
              // this.calculateFilteredResults();
          }
      },

    }
}
</script>

<style scoped>

.firstCol {
    height: 100%;
    width: calc(100vw * 0.85);
    max-width: 100vw;
    background: var(--v-greyRaised-lighten2) !important;
    contain: content;
}

.secondCol {
    height: 100%;
    min-width: 0px;
    width: calc(100vw * 0.15);
    background: var(--v-greyRaised-lighten2) !important;
}
.listItemLeft {
    min-height: 38px;
    height: 38px;
    padding: 0 0px !important;
}

.v-list-item {
    min-height: 30px;
    height: 30px;
    padding: 0px 0px !important;
}
.v-list-item__avatar {
    margin: 0 0px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
}

.v-list-item__avatar ::v-deep .v-icon::after {
    height: auto !important;
}
.v-list-item__action ::v-deep .v-icon::after {
    height: auto !important;
}
.v-list-item__action {
    margin: 0 0px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
    display: flex !important;
    place-content: center !important;
}
.v-list-item__content {
  padding: 4px;
}

.v-list-item__title {
    font-size: 0.75rem !important;
    line-height: 1.4 !important;
}

.v-list-item__subtitle {
    font-size: 0.625rem !important;
    opacity: 0.7 !important;
}
.inputTextField ::v-deep input {
  text-align: center;
  padding: 0 0 3px 0;
}
.textFieldSearch ::v-deep div.v-input__control div.v-input__slot {
    font-size: 0.75rem !important;
    max-height: 24px !important;
    min-height: auto !important;
    padding: 0 4px;
}

.textFieldSearch ::v-deep button.v-icon.v-icon--link {
    font-size: 20px;
}

.textFieldSearch.hideInput:active ::v-deep input {
    width: 100%;
    max-width: 100%;
}
.textFieldSearch.hideInput ::v-deep input {
    width: 1px;
    max-width: 1px;
}

.formInputs ::v-deep div.v-input__control div.v-input__slot {
    font-size: 0.75rem !important;
    max-height: 35px !important;
    min-height: auto !important;
    padding: 0 4px !important;
}
.formInputs ::v-deep div.v-input__prepend-inner {
    padding-top: 4px !important;
    margin-top: 0px !important;
}
.formInputs ::v-deep div.v-input__append-inner {
    padding-top: 4px !important;
    margin-top: 0px !important;
}
.formInputs ::v-deep i.v-icon {
    font-size: 20px !important;
}
.formInputs ::v-deep label.v-label {
    font-size: 0.9rem !important;
    top: 7px;
}
.formInputs.formInputs.v-input--is-label-active ::v-deep label.v-label, 
.formInputs.formInputs.v-input--is-focused ::v-deep label.v-label {
  left: -20px !important;
} 
.formInputs.v-input--is-focused ::v-deep svg{
  fill: var(--v-primary-base);
}

.formInputs ::v-deep .v-select__selections {
    flex: none !important;
    flex-wrap: nowrap !important;
    max-width: 82%;
    max-height: 32px;
}


.buttonSwitch {
    margin-left: 1px;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;
}
.seasons ::v-deep .v-slide-group__content  {
    padding: 0;
}
.numInput ::v-deep .el-input-number__decrease {
  border-radius: 0px 0px 6px 0px;
}
.numInput ::v-deep .el-input-number__increase {
  border-radius: 0px 6px 0px 0px;
}
.numInput ::v-deep .el-input-number__increase.is-disabled, .numInput ::v-deep .el-input-number__decrease.is-disabled {
  background: var(--v-greyRaised-base) !important;
}
.numInput ::v-deep .el-input-number__decrease, .numInput ::v-deep .el-input-number__increase{
  background: var(--v-primary-darken1);
  height: 11px !important;
  border: none;
}
.numInput {
  line-height: 21px !important;
  width: 60px !important;
  border-radius: 6px;
}
.numInput ::v-deep .el-input__inner {
  border: 0;
  border-radius: 6px;
  height: 22px;
  line-height: 21px;
  padding: 0px 28px 0px 0px;
}

.expandArrow {
  rotate: 180deg;
  margin: -5px 0;
  transition: rotate 0.3s;
}

.expandArrow.active {
  rotate: 0deg
}

.forecastModal {
  top: 100vh;
  transition: top 0.3s;
}
.forecastModal.active {
  top: 0;
}

.ag-grid-theme-builder ::v-deep .ag-center-cols-viewport {
  background: var(--v-greyRaised-lighten2) !important;
}

.ag-grid-theme-builder ::v-deep .ag-row {
  background: var(--v-greyRaised-lighten2) !important;
}
.ag-grid-theme-builder ::v-deep .ag-body-viewport {
  background: var(--v-greyRaised-lighten2) !important;  
}
.ag-grid-theme-builder ::v-deep .ag-row-last {
  border-bottom: 1px solid rgb(255, 255, 255, 15%);
  background: var(--v-greyRaised-lighten2) !important;
}
</style>