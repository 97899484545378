<template>
    <div style="line-height: 1;">
        <div style="font-size: 10px;">
            {{value?.name ?? ''}} {{ ' ' }}
        </div>
        <div style="font-size: 8px; opacity: 0.7; ">{{ value?.parentProduct?.name ? `${value.parentProduct.name}` : '' }}</div>
    </div>
</template>

<script>
export default {
    data: () => ({
        value: null,
    }),
    beforeMount() {
        this.value = this.params.value
    },
}


</script>